export const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: '#000',
  border: '2px solid #ec5f59',
  boxShadow: 24,
  p: 4,
};

export const customConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const buttonStyle = {
  borderRadius: '7px',
  height: '45px',
  backgroundColor: '#ec5f59',
  m: 0,
  p: 1,
  '&.Mui-disabled': {
    opacity: 0.7,
  },
};
export const hoverStyle = {
  backgroundColor: 'rgba(236, 95, 89, 0.5)',
};

export const textStyle = { color: '#FFF', fontSize: 14, fontWeight: 700 };
