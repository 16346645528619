import { useState, useEffect, useContext } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { styled } from '@mui/system';

import { formatUnits } from 'viem';

import httpClient from '../../utils/http-client';
import { UserDetailsContext } from '../../contexts/UserContext';
import { AVAX_EXPLORER_URL } from '../../utils/config';
import { colors, blackGradient } from '../../utils/colors';

import Template from './Templates/UserPageTemplate';
import TableTemplate from './Templates/TableTemplate';

interface Transaction {
  createdAt: string;
  updatedAt: string;
  id: string;
  address: string;
  amount: string;
  hash: string | null;
  userBalanceSnapshot: string;
  action: 'deposit' | 'withdraw';
  chain: string;
  deletedAt: string | null;
}

const ShadowedLine = styled('div')({
  boxShadow: '0px -2px 6px 0px rgba(236, 95, 89, 0.6)',
  padding: '0px',
  borderBottom: '2px solid #ec5f59',
  width: '16px',
  transition: 'opacity 0.5s ease',
});

const statusCellStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'max-content',
  color: '#ffffff',
  textDecoration: 'none',
  padding: '8px',
  borderRadius: '4px',
  fontWeight: 700,
};

const getMonthName = (monthNumber: number) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return monthNames[monthNumber];
};

const formatDate = (dateString: string) => {
  const originalDate = new Date(dateString);
  const formattedDate = `${originalDate.getDate()} ${getMonthName(originalDate.getMonth())} ${originalDate.getFullYear().toString().slice(2)}, ${originalDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}`;
  return formattedDate;
};

const depositWithdrawalColumns = [
  {
    id: 'date',
    label: 'Date',
    renderCell: (row: Transaction) => (
      <Typography sx={{ color: '#fff', fontWeight: 500, opacity: 0.85 }}>
        {formatDate(row.createdAt)}
      </Typography>
    ),
  },
  {
    id: 'amount',
    label: 'Amount',
    renderCell: (row: Transaction) => (
      <Typography sx={{ color: '#fff', fontWeight: 700 }}>
        {Number(formatUnits(BigInt(row.amount), 18)).toFixed(2)}
      </Typography>
    ),
  },
  {
    id: 'status',
    label: 'Status',
    renderCell: (row: Transaction) =>
      row.hash || row.action === 'withdraw' ? (
        <a
          href={`${AVAX_EXPLORER_URL}tx/${row.hash || '0x'}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            ...statusCellStyles,
            backgroundColor: '#4CAF50',
            cursor: 'pointer',
            userSelect: 'none',
          }}
        >
          Success <OpenInNew style={{ marginLeft: '4px' }} />
        </a>
      ) : (
        <Box
          sx={{
            ...statusCellStyles,
            backgroundColor: '#f44336',
            userSelect: 'none',
          }}
        >
          Uknown
        </Box>
      ),
  },
];

const tables = [{ label: 'Deposits' }, { label: 'Withdrawals' }];

export default function Transactions() {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTable, setSelectedTable] = useState(tables[0].label);
  const [currentRows, setCurrentRows] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [deposits, setDeposits] = useState<Transaction[]>([]);
  const [withdrawals, setWithdrawals] = useState<Transaction[]>([]);

  const maxRows = 20;

  const { address, userJWT } = useContext(UserDetailsContext);

  useEffect(() => {
    if (!address || !userJWT) return;
    const getWalletHistory = async () => {
      if (address && userJWT) {
        const response = await httpClient.post('user/wallet-history', {
          address,
        });
        setDeposits(response.data.deposits.reverse());
        setWithdrawals(response.data.withdrawals.reverse());
        setIsLoading(false);
      }
    };
    getWalletHistory();
  }, [address, userJWT]);

  useEffect(() => {
    setCurrentRows(getCurrentRows());
  }, [selectedTable, deposits, withdrawals]);

  const getCurrentRows = () => {
    switch (selectedTable) {
      case tables[0].label:
        return deposits;
      case tables[1].label:
        return withdrawals;
      default:
        return [];
    }
  };

  const handleButtonClick = (label: string) => {
    setCurrentPage(1);
    setSelectedTable(label);
    setCurrentRows(getCurrentRows());
  };

  return (
    <Template>
      <Box
        sx={{
          width: '100%',
          p: '8px',
          backgroundColor: colors.blackRussian,
          backgroundImage: blackGradient,
          borderRadius: '8px',
          minHeight: '512px',
        }}
      >
        <Box sx={{ display: 'flex', gap: '32px' }}>
          {tables.map((button) => (
            <Button
              key={button.label}
              variant="text"
              onClick={() => handleButtonClick(button.label)}
              sx={{
                flexDirection: 'column',
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 600,
                  textTransform: 'none',
                  color: '#FFF',
                  paddingBottom: '2px',
                  opacity: selectedTable === button.label ? 1 : 0.5,
                }}
              >
                {button.label}
              </Typography>
              <ShadowedLine
                sx={{ opacity: selectedTable === button.label ? 1 : 0 }}
              />
            </Button>
          ))}
        </Box>
        <Box sx={{ mt: '16px' }}>
          {currentRows.length > 0 || isLoading ? (
            <TableTemplate
              columns={depositWithdrawalColumns}
              rows={currentRows}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isLoading={isLoading}
              hasNext={currentRows.length >= currentPage * maxRows}
              maxRows={maxRows}
            />
          ) : (
            <Typography variant="h5" sx={{ color: '#fff', opacity: 0.75 }}>
              No data to display
            </Typography>
          )}
        </Box>
      </Box>
    </Template>
  );
}
