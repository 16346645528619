import { useQuery } from '@apollo/client';
import { Skeleton } from '@mui/material';
import React from 'react';
import { PARTNER_ID } from '../utils/config';
import { GET_PARTNER_BY_NAME } from '../graphql/queries';

interface CurrencyFormatterProps {
  value: number;
}

const CurrencyFormatter: React.FC<CurrencyFormatterProps> = (props) => {
  const { value } = props;
  // Allow the partner id in the query to override the localstorage value
  const { data, loading } = useQuery(GET_PARTNER_BY_NAME, {
    variables: {
      name: PARTNER_ID,
    },
  });

  const { decimalPlaces = 0, currencyCode } = data?.partner || {};

  if (!data || loading) {
    return <Skeleton variant="text" sx={{ fontSize: '1rem' }} />;
  }

  const formattedValue = (value / Math.pow(10, decimalPlaces)).toFixed(
    decimalPlaces,
  );

  return (
    <span
      data-testid={
        loading ? 'currency-formatter-loading' : 'currency-formatter'
      }
    >
      {formattedValue} {currencyCode}
    </span>
  );
};

export default CurrencyFormatter;
