import { gql } from '@apollo/client';

const EVENT_FRAGMENT = gql`
  fragment EventDetail on Event {
    id
    team1
    team2
    startTime
    isFinished
  }
`;

const BET_FRAGMENT = gql`
  fragment BetDetail on Bet {
    id
    stake
    stakeCurrency
    createdTime
    type
    state
    settlement
    components(count: 20) {
      id
      outcome {
        id
        name
        odds
      }
      market {
        id
        name
      }
      settlement
      event {
        ...EventDetail
        competition {
          id
          name
          sport {
            name
          }
        }
      }
    }
  }
  ${EVENT_FRAGMENT}
`;

export const GET_BETS = gql`
  query Bets(
    $sportId: String
    $minStake: Int
    $maxStake: Int
    $count: Int
    $startIndex: Int
    $startDate: String
    $endDate: String
    $sortBy: String
    $settlement: String
  ) {
    bets(
      sportId: $sportId
      minStake: $minStake
      maxStake: $maxStake
      count: $count
      startIndex: $startIndex
      startDate: $startDate
      endDate: $endDate
      sortBy: $sortBy
      settlement: $settlement
    ) {
      ...BetDetail
      user {
        externalPartnerId
      }
      statistics {
        potentialWin
        actualWin
        totalOdds
      }
      createdTime
    }
  }
  ${BET_FRAGMENT}
`;

export const GET_PARTNER_BY_NAME = gql`
  query Partner($name: String!) {
    partner(name: $name) {
      name
      currencyCode
      decimalPlaces
    }
  }
`;

export const GET_BET = gql`
  query Bet($id: ID) {
    bet(id: $id) {
      ...BetDetail
      statistics {
        potentialWin
        actualWin
        totalOdds
      }
    }
  }
  ${BET_FRAGMENT}
`;

export const GET_SPORTS = gql`
  query Sports($startIndex: Int) {
    sports(startIndex: $startIndex) {
      name
      id
    }
  }
`;
