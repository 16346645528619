import React, { useContext } from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { McoqPriceContext } from '../../../contexts/McoqPriceContext';

const InfoIcon = ({ width = 16, fill = 'currentColor' }) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
  >
    <path
      d="M10 9.167a.833.833 0 0 0-.833.833v3.333a.833.833 0 0 0 1.666 0V10A.833.833 0 0 0 10 9.167Zm.317-3.267a.833.833 0 0 0-.634 0 .833.833 0 0 0-.275.175.958.958 0 0 0-.175.275.833.833 0 0 0 .175.908.958.958 0 0 0 .275.175.833.833 0 0 0 1.15-.766.875.875 0 0 0-.241-.592.834.834 0 0 0-.275-.175ZM10 1.667a8.333 8.333 0 1 0 0 16.666 8.333 8.333 0 0 0 0-16.666Zm0 15a6.666 6.666 0 1 1 0-13.333 6.666 6.666 0 0 1 0 13.333Z"
      fill={fill}
    />
  </svg>
);

const TooltipTitle = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1.5,
        whiteSpace: 'nowrap',
      }}
    >
      <Typography component="span" sx={{ fontSize: '12px', fontWeight: 600 }}>
        Max Payout 25 000.00 MCOQ
      </Typography>
    </Box>
  );
};

const InputLabel = ({ betAmount }: { betAmount: number }) => {
  const { price } = useContext(McoqPriceContext);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        pb: '6px',
      }}
    >
      <Box
        sx={{ display: 'flex', alignItems: 'center', gap: 1, fontSize: '12px' }}
      >
        <Typography
          sx={{ fontSize: '12px', lineHeight: '24px', verticalAlign: 'middle' }}
        >
          Bet Amount
        </Typography>
        {/* <Tooltip title={<TooltipTitle />} arrow placement="right">
          <Box sx={{ width: 'fit-content', display: 'inline-flex', cursor: 'pointer', alignItems: 'center' }}>
            <InfoIcon />
          </Box>
        </Tooltip> */}
      </Box>
      <Typography>${(betAmount * price).toFixed(2)}</Typography>
    </Box>
  );
};

export default InputLabel;
