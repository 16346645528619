import { useState, useContext, useEffect, useCallback } from 'react';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { ConnectButton } from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';

import { useAccount } from 'wagmi';

import { UserDetailsContext } from '../../contexts/UserContext';
import DepositWithdrawalInsert from './DepositWithdrawal/DepositWithdrawalInsert';
import { buttonStyle, style, hoverStyle } from './utils';

export default function CoqConnectButton() {
  const account = useAccount();
  const [open, setOpen] = useState(false);
  const [connectButtonClicked, setConnectButtonClicked] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { handleConnect, handleTransaction, logout, userJWT, address } =
    useContext(UserDetailsContext);

  const logUserOut = useCallback(() => {
    logout();
    setConnectButtonClicked(false);
    handleClose();
  }, [logout, setConnectButtonClicked]);

  // 1-click login
  useEffect(() => {
    if (connectButtonClicked && account.address) {
      handleConnect();
    }
  }, [connectButtonClicked, account.address]);

  function createButton(
    text: string,
    onClick: React.MouseEventHandler,
  ): React.JSX.Element {
    return (
      <Button
        sx={{ ...buttonStyle, '&:hover': hoverStyle, textTransform: 'none' }}
        onClick={onClick}
      >
        <Typography sx={{ mt: '3px', fontWeight: 700, color: '#FFF' }}>
          {text}
        </Typography>
      </Button>
    );
  }

  return (
    <ConnectButton.Custom>
      {({ account, chain, mounted, openConnectModal, openChainModal }) => {
        const ready = mounted;
        const connected = ready && account && chain;
        const handleOpenConnectModal = () => {
          openConnectModal();
          setConnectButtonClicked(true);
        };

        if (chain?.unsupported) {
          return createButton('Wrong Network', openChainModal);
        }

        if (userJWT !== '') {
          return (
            <div>
              {createButton(address.slice(0, 8) + '...', handleOpen)}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    align="center"
                    sx={{ my: 4, color: '#FFF', mt: '3px', fontWeight: 700 }}
                  >
                    {address.slice(0, 8)}...{address.slice(-5)}
                  </Typography>
                  <DepositWithdrawalInsert
                    logoutAction={logUserOut}
                    handleTransaction={handleTransaction}
                  />
                </Box>
              </Modal>
            </div>
          );
        }

        if (!connected) {
          return createButton('Connect Wallet', handleOpenConnectModal);
        }
        return createButton('Sign In', handleConnect);
      }}
    </ConnectButton.Custom>
  );
}
