import { DateTime } from 'luxon';
import { BETTIMEOPTIONS } from './config';

type TimeRange = {
  startDate: Date;
  endDate: Date;
};
type TimeOption = (typeof BETTIMEOPTIONS)[number];
export const getIntervalDates = (timeOption: TimeOption, range?: TimeRange) => {
  switch (timeOption) {
    case BETTIMEOPTIONS[0]:
      return {
        startDate: DateTime.local().startOf('day').toString(),
        endDate: DateTime.local().endOf('day').toString(),
      };
    case BETTIMEOPTIONS[1]:
      return {
        startDate: DateTime.local().startOf('week').toString(),
        endDate: DateTime.local().endOf('week').toString(),
      };
    case BETTIMEOPTIONS[2]:
      return {
        startDate: DateTime.local().startOf('month').toString(),
        endDate: DateTime.local().endOf('month').toString(),
      };
    case BETTIMEOPTIONS[4]:
      if (!range) {
        return {
          startDate: '',
          endDate: '',
        };
      }
      return {
        startDate: DateTime.fromJSDate(range.startDate)
          .startOf('day')
          .toString(),
        endDate: DateTime.fromJSDate(range.endDate).endOf('day').toString(),
      };
    default:
      // return {
      //   startDate: DateTime.now().minus({ years: 10 }).toString(),
      //   endDate: DateTime.now().toString()
      // }
      return {
        startDate: '',
        endDate: '',
      };
  }
};
