import React, {
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography, styled, Grid } from '@mui/material';

import { useQuery } from '@apollo/client';
import { GET_BETS, GET_BET } from '../../graphql/queries';
import { PARTNER_ID } from '../../utils/config';
import { UserDetailsContext } from '../../contexts/UserContext';
import { BetStreamContext } from '../../contexts/BetStreamContext';

import BetCardSkeleton from './BetCardSkeleton';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import BetCard from './BetCard';

const LoadingIcon = styled(AutorenewIcon)(({ theme }) => ({
  animation: 'spin 1s linear infinite',
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  color: '#ec5f59',
  fontSize: '2rem',
  margin: '16px auto',
  position: 'absolute',
  bottom: '-32px',
  left: '50%',
  zIndex: 10,
}));

type TimeRange = {
  startDate: string;
  endDate: string;
};
type BetsProps = {
  stakeSizeFilter: { minStake: number | null; maxStake: number | null };
  stringDateRange: TimeRange;
  settlementFilter: string;
  sportFilter: string;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};
const Bets = ({
  stakeSizeFilter,
  stringDateRange,
  settlementFilter,
  sportFilter,
  isLoading,
  setIsLoading,
}: BetsProps) => {
  const { userJWT } = useContext(UserDetailsContext);
  const { setBetId, betId } = useContext(BetStreamContext);
  const [startIndex, setStartIndex] = useState(0);
  const [bets, setBets] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const betVariables = useMemo(
    () => ({
      startIndex: 0,
      count: 20,
      ...stringDateRange,
      settlement: settlementFilter,
      sportId: sportFilter,
      ...(betId === '' && stakeSizeFilter.minStake
        ? { minStake: stakeSizeFilter.minStake * 100 }
        : {}),
      ...(betId === '' && stakeSizeFilter.maxStake
        ? { maxStake: stakeSizeFilter.maxStake * 100 }
        : {}),
    }),
    [stringDateRange, settlementFilter, stakeSizeFilter, betId, sportFilter],
  );

  const {
    loading,
    error,
    fetchMore,
    refetch,
    startPolling,
    stopPolling,
    data,
  } = useQuery(GET_BETS, {
    context: {
      headers: {
        authorization: `Bearer ${userJWT}`,
        'x-aji-partner-id': PARTNER_ID,
      },
    },
    skip: !userJWT || betId !== '',
    variables: betVariables,
    fetchPolicy: 'network-only',
  });

  const {
    loading: loadingBet,
    error: errorBet,
    refetch: refetchBet,
  } = useQuery(GET_BET, {
    context: {
      headers: {
        authorization: `Bearer ${userJWT}`,
        'x-aji-partner-id': PARTNER_ID,
      },
    },
    skip: !betId,
    variables: { id: betId },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setIsLoading(loading || loadingBet);
  }, [loading, loadingBet, setIsLoading]);

  useEffect(() => {
    startPolling(10000);
    return () => stopPolling();
  }, [startPolling, stopPolling, bets]);

  useEffect(() => {
    if (!loading && data && data.bets) {
      const newFetchedBets = data.bets.filter(
        (bet) =>
          !bets.some((b) => b.id === bet.id && b.settlemnt === bet.settlemnt),
      );
      if (newFetchedBets.length > 0)
        setBets((prevBets) => [...newFetchedBets, ...prevBets]);
    }
  }, [data]);

  useEffect(() => {
    if (userJWT) {
      setBetId('');
      setStartIndex(0);
      setHasMore(true);
      setBets([]);
      refetch().then(({ data }) => setBets(data.bets));
    }
  }, [
    stringDateRange,
    userJWT,
    settlementFilter,
    stakeSizeFilter,
    sportFilter,
  ]);

  useEffect(() => {
    if (!userJWT) return;
    setStartIndex(0);
    setBets([]);
    if (betId) {
      setHasMore(false);
      refetchBet({ id: betId }).then(({ data }) => setBets([data.bet]));
    } else if (userJWT && !betId) {
      setBetId('');
      setHasMore(true);
      refetch().then(({ data }) => setBets(data.bets));
    }
  }, [betId]);

  useEffect(() => {
    if (!hasMore || !bets.length) return;
    setIsLoading(true);
    fetchMore({
      variables: {
        ...betVariables,
        startIndex: startIndex,
      },
    })
      .then(({ data }) => {
        setBets((prevBets) => [...prevBets, ...data.bets]);
        if (data.bets.length < 20) {
          console.log('no more bets');
          setHasMore(false);
        }
      })
      .finally(() => setIsLoading(false));
  }, [startIndex]);

  const observer = useRef<IntersectionObserver | null>(null);
  const lastBetElementRef = useCallback(
    (node) => {
      if (loading || !hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setStartIndex((prevStartIndex) => prevStartIndex + 20);
          }
        },
        { threshold: 0.1, rootMargin: '100px' },
      );
      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  if (!userJWT)
    return (
      <Typography py={8} variant="h5" color={'#fff'}>
        Log in to see bet activity.
      </Typography>
    );
  if (error || errorBet)
    return (
      <Typography py={8} variant="h5" color={'#fff'}>
        Error: {(error || errorBet || {}).message}
      </Typography>
    );

  if (
    !bets.length &&
    userJWT &&
    !loading &&
    !loadingBet &&
    !isLoading &&
    !data?.bets?.length
  )
    return (
      <Typography variant="h5" color={'#fff'}>
        No bets
      </Typography>
    );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'start',
        height: '100%',
        width: '100%',
        color: '#fff',
        p: '16px',
        m: '0 auto',
        backgroundColor: '#000',
        position: 'relative',
      }}
    >
      <Grid container spacing={2}>
        {!bets.length || searchParams.get('modal') === 'bet'
          ? Array.from(new Array(20)).map((_, index) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                xl={3}
                key={`bet-skeleton${index}`}
              >
                <BetCardSkeleton />
              </Grid>
            ))
          : Array.from(bets).map((bet, index) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                xl={3}
                key={`${bet.id}-${bet.settlement}`}
                ref={index === bets.length - 1 ? lastBetElementRef : null}
              >
                <BetCard bet={bet} />
              </Grid>
            ))}
      </Grid>
      <LoadingIcon sx={{ display: isLoading ? 'block' : 'none' }} />
    </Box>
  );
};

export default Bets;
