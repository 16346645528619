import React from 'react';
import Box from '@mui/material/Box';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PageWrapper from '../../wrappers/PageWrapper';
import { colors } from '../../utils/colors';

import './supportStyles.css';

const faqs = [
  {
    question: 'Who do I contact if I have an issue with the site?',
    answer:
      "Contact support in our discord server. There's a link to the discord in the left sidebar. Alternatively, dm @coqbook on X.",
  },
  {
    question: 'My deposit failed, what should I do?',
    answer:
      "Try the manual deposit UI in the deposit / withdrawal modal. If that fails, contact support in our discord channel. There's a link to the discord in the left sidebar. Alternatively, dm @coqbook on X.",
  },
  {
    question:
      'Where are house funds kept? How can I be certain there are sufficient funds to pay me in case I win big?',
    answer:
      'House funds that are instantly withdrawable are found in the House contract (0xD0Ce7961Ec9500f5DAa734061f466613882A56DF). Additional house funds are found in the house owner hot wallet (0xcD9287417DBcE9cB773bB322fAeD617367F79F06) and the coqbook cold wallet (0x44266c09162a1d02560984AC819E2A1c83494A8E).',
  },
  {
    question: 'What is the maximum withdrawal? How often can I withdraw?',
    answer:
      'Users can withdraw once every 2 hours & the maximum withdrawal is 1500 MCOQ--the maximum withdrawal will increase when we end our beta-testing phase.',
  },
  {
    question:
      'Why do my bets sometimes get rejected even though I have sufficient balance to place the bet?',
    answer:
      "We have tight risk limits while we're in beta & if betting gets too lopsided on a particular match, we will block additional bets on the team with most of the bets to limit the maximum potential loss.",
  },
  {
    question: 'What are the COQBOOK terms & conditions?',
    answer: `Last updated: 1st August, 2024

These terms and conditions (“Terms” or “Agreement”) set out the basis upon which You may access and make use of, and the basis upon which we may supply, our Services to You from coqbook.com (the “Website”). Please be sure to read these Terms carefully as they form a legally binding contract between You and us.

ACCEPTANCE
By registering an account with the Website, You enter into a contract with COQBOOK N.V, and agree to be bound by:
these Terms & Conditions;
our Privacy Policy;
our Cookies Policy;
any ancillary game rules which may apply to certain games offered as part of the Services, and which may be displayed within the relevant game interface including, but not limited to, any game limits and game maximum wins (“Game Rules”);
any specific conditions which are stated as applying when interacting with a particular aspect of the Services;
all such other of our terms and conditions, rules or policies as they relate to any applicable Services, promotions, bonuses, special offers or any other aspect of Your use of the Services from time to time;
and are deemed to have accepted and understood all the terms.
Please read this Agreement carefully to make sure You fully understand its content and the rules and conditions that apply to You when accessing and using the Website or any of the Services offered. If You have any doubts as to Your rights and obligations resulting from the acceptance of this Agreement, please consult a legal advisor in Your jurisdiction before further using the Website and accessing its content.
Each time You visit our Website and/or access our Services, You will be deemed to have accepted these Terms anew and as drafted for the time being, including any amendments which may have been made to the Terms since Your last visit and/or use of the Services. If You do not agree to accept and be bound by these Terms, You must not use our Services and Website.

GENERAL NOTES
These Terms come into force as soon as You complete the registration process, which includes checking the box accepting these Terms and successfully creating an account. By using any part of the Website following account creation, You agree to these Terms applying to the use of the Website.
The terms noted in these Terms shall prevail in the event of any conflict between the terms within these Terms and of any of the game rules or other documents referred to in these Terms.

ACCOUNTS AND ACCOUNT VERIFICATION
 When You register an account with us (a “COQBOOK Account”) You are agree, warrant, and represent that:
You are at least 18 years of age or if greater, the age at which gambling is legal under any law that applies to You;
You will provide accurate information when registering Your account including, without limitation, Your full name, date of birth, Your current residential address, Your email address and a personal telephone number; and You will inform us of any changes to this information;
You are legally capable of entering into binding contracts, including these Terms and any interactions with the Services that might amount to, or create, a binding contract;
You are registering the account solely for Your own personal use, and that You are acting on Your own behalf and not on behalf of a third party;
You will not try to sell or in any way transfer the benefit of Your account to any third party and nor will You acquire or attempt to acquire an account which has been opened in the name of a third party;
You are entirely responsible for complying, and that You do so comply and will continue to comply, with any and all local, national, federal, provincial or other laws which may be applicable to You from time to time and which might affect Your ability to access and use the Services;
You are not prohibited for any reason from using the Services including, but not limited to, by way of court order;
You are not diagnosed or classified as a compulsive or problem gambler;
You are not currently self-excluding yourself from another COQBOOK account.
You are not permitted to register or use more than one COQBOOK Account for the purposes of accessing the Services without prior agreement, which will be reviewed on a case by case basis. If You notice that You have more than one registered COQBOOK Account You must notify us immediately. Failure to do so may lead to Your COQBOOK Account being blocked and the funds retained.
We may limit the creation of accounts to one per person, family, household address, email address, telephone number, IP address, payment method, linked e-wallet accounts, mobile device, computer and/or application download instance. Under no circumstances will we be obliged to circumvent or lift these restrictions, but we may consider any justifiable request to do so on a case-by-case basis. Please contact our customer support team for assistance on discord or twitter.
Should we believe, in our sole and absolute discretion, that You have registered and/or used more than one account with us in contravention of clause ‎4.2, we may restrict, suspend and/or permanently close any and/or all such accounts, withhold any winnings linked to said accounts and levy an administration charge for dealing with the same.
The availability of the Services does not constitute an offer, solicitation or invitation by us for the use of our Services in any jurisdictions in which such use is prohibited by law. If You use our Services in breach of any local, national, federal, provincial, state or other laws that apply to You, we will not be liable to You for any consequences of such use. You are responsible for researching the laws applicable in your locality or country. You must ensure that you are complying with the law in the jurisdiction in which you are using the Website or any Service.
You must keep Your personal information updated and current at all times; failure to do so may limit our ability to provide certain of the Services to You including, but not limited, the processing of deposit and/or withdrawal requests in a timely manner, or at all. You may update Your details at any time by accessing the account section of our Website; should You require assistance in updating Your personal information You may contact our customer support team for assistance on discord or twitter.
By accepting these Terms in registering or using the Website You acknowledge and agree that we are entitled to conduct any and all such identification, credit, fraud and other verification checks from time to time that we may require and/or may be required by applicable laws and regulations and/or by the relevant regulatory authorities for use of the Website and our Services generally. You agree that You will provide us with all information that we require in connection with such verification checks. We will be entitled to suspend or restrict Your account in any way that we deem appropriate, until such time as we have completed those verification checks to our sole satisfaction.
As part of the registration and verification process, we may transfer any information provided to us pursuant to clause ‎4.7 to certain third parties for the purposes of assisting with and completing any verification process, and You specifically agree and consent to such transfers and processing.
It is Your sole and exclusive responsibility to ensure that Your login details are kept securely. You must not disclose Your login details to anyone.
We are not liable or responsible for any abuse or misuse of Your COQBOOK Account by third parties due to Your disclosure, whether intentional, accidental, active or passive, of Your login details to any third party.
You are responsible for all activities that occur under Your COQBOOK Account.

SUSPENSION AND CLOSURE
 COQBOOK shall be entitled to close or suspend Your COQBOOK Account if:
COQBOOK considers that You are using or have used the Website in a fraudulent or collusive manner or for illegal and/or unlawful or improper purposes;
COQBOOK considers that You are using or have used the Website in an unfair manner, have deliberately cheated or taken unfair advantage of COQBOOK, its third party providers, any of its customers or if Your COQBOOK Account is being used for the benefit of a third party;
COQBOOK is requested to do so by any applicable law enforcement authority, any regulatory authority or court or if COQBOOK is unable to verify Your identity, location, or source of funds as is expressly required by us in order to comply with our legal obligations;
You are in breach of these Terms, the applicable regulations (including regulations applicable in Your jurisdiction) or the fair use of our services;
COQBOOK has reasonable grounds to suspect that You are a compulsive problem gambler without being self-excluded;
COQBOOK considers that any of the events referred to in (i) to (v) above may have occurred or are likely to occur.
If we close or suspend Your COQBOOK Account for any of the reasons referred to in 5.1, You shall, to the extent permitted by applicable laws, be liable for any and all claims, direct losses, liabilities, damages, costs and expenses incurred or suffered by us (together, the “Claims”) arising therefrom and shall, to the extent permitted by applicable laws, indemnify us on demand for such Claims.
In the circumstances referred to in 5.1, we shall also be entitled to void any bets placed by You following such actions by You or to withhold and/or retain any and all amounts which would otherwise have been paid or payable to You (including any winnings).
In the interests of fair play for all, You are not permitted to utilise or deploy any novel or recognised betting techniques while using our Website which are designed to circumvent the standard house edge in our games; should we detect that You have engaged in activity of this nature, we may immediately suspend, terminate or restrict access to Your COQBOOK Account and/or retain the balance of any funds therein.
We reserve the right to suspend or terminate Your account at any time, with or without notice, if we suspect that Your COQBOOK Account has been compromised or is being used in breach of our Terms of Service.
If we close Your COQBOOK Account, and believe, at our sole discretion, that any portion or all of the remaining balance is due to You, we will inform You of the available means to withdraw that remaining balance.

Jurisdiction & Restricted Territories
As at the time of writing, You are forbidden to register on the Website and / or use our services if You are a resident of any of the following territories:
Afghanistan, Aruba, Australia, Belgium, Bonaire, Curacao, Cyprus, Denmark, Estonia, France, Germany, Hungary, Iran, Iraq, Italy, Netherlands, North Korea, Saba, Slovakia, Spain, St Maarten, Statia, Turkey, Ukraine, United Kingdom, United Arab Emirates, U.S.A or the U.S.A dependencies (“Restricted Territories”).

In addition to the Restricted Territories, we reserve the right to refuse customers from any other country.
Particular third party provider games are also not available in certain countries due to licencing and local restrictions.
We deploy technological solutions to prevent persons located in Restricted Territories from registering an account and/or otherwise being able to access, or to use, the Services. You acknowledge and agree that we will not be responsible to You for, and will be indemnified in relation to, any losses suffered by You as a result of Your attempting to access the Website and the Services from within a Restricted Territory, including by way of any means designed to circumvent any controls or technological solutions that we may have in place from time to time, and which are intended to prevent or limit such access.
By accessing the Website and Services, and entering into this agreement, You confirm that You are not located in a Restricted Territory or a jurisdiction in which it is illegal to use the Services.
You will not use our Services while located in any jurisdiction that prohibits the placing and/or accepting of bets online and/or playing casino and/or live games;
You accept and acknowledge that we reserve the right to detect and prevent the use of prohibited techniques, including but not limited to fraudulent transaction detection, automated registration and signup, gameplay and screen capture techniques. These steps may include, but are not limited to, examination of Players device properties, detection of geo-location and IP masking, transactions and blockchain analysis;

DEPOSITS & FUNDS
Prior to Your use of the Service and on an ongoing basis You represent, warrant, covenant and agree that:
There is a risk of losing cryptocurrency when using the Service and that COQBOOK has no responsibility to You for any such loss;
You will not deposit funds which originate from criminal or other unauthorised activity;
You will not deposit funds using payment methods that do not belong to You;
All the funds deposited shall exclusively be used for Services available on the Website;
You will not withdraw or try to withdraw to a payment methods that do not belong to You;
You understand that by participating in the Services available on the Website, You take the risk of losing money deposited.
You accept and acknowledge that the value of cryptocurrency can change dramatically depending on the market value;
COQBOOK shall not be treated as a financial institution;
We may be required by anti-money laundering regulations, and as part of our internal policies, to make checks on the source of any funds placed on deposit by our customers. In the event that our checks do not provide us with sufficient information about You, we may request further information from You from time to time and You agree to promptly provide such information when requested to do so. We reserve the right to suspend or terminate Your account should we be unable to satisfy ourselves that Your source of funds is legitimate.
Deposits may be subject to minimum and/or maximum limits, which may be determined by the method of deposit, Your account and gameplay history, limits imposed by You on yourself, or as otherwise determined by us at our sole discretion.
Deposited funds will be credited to Your account upon actual receipt of the same by us and/or our agents, as the case may be.
We will hold funds deposited to Your account separate from our own operational funds. In the event of insolvency, we provide no guarantee that deposited funds would be considered separate to our other assets and that all, or any, of Your funds will be returned to You.
COQBOOK is not a financial institution, and funds deposited to Your COQBOOK Account are not insured by any government agency, or otherwise protected by a deposit security scheme, nor will they bear interest.
Any amounts owed by You to COQBOOK pursuant to these Terms may be deducted, whether in full or in part, from funds held in Your COQBOOK Account without notice.
You agree to only deposit funds which You intend to use to interact with and/or make use of the Services. If we believe, in our sole and absolute discretion, that You have deposited funds with no intention of using said funds for the purpose of interacting with and/or making use of the Services, we may suspend or close Your account and may be required to report the matter to the relevant authorities.
PLACING BETS / WAGERS & FAIRNESS OF GAMES
You may only bet up to the lesser of (i) the amount held in Your COQBOOK Account, (ii) any limits set in the Game Rules. We will determine in our absolute discretion what the minimum and maximum bet selection is for each bet and this will be made clear on the Website before You place Your bet.
We will not be responsible, or liable to You, under any circumstances if You play a game and have not fully understood the Game Rules and/or how the game is operated.
You confirm that You are fully aware that there is a risk of losing money when interacting with the Services. By using our Services, You acknowledge and agree that You are wholly responsible for any losses You may suffer. You agree that Your use of the Services is at Your sole option, discretion and risk and further, that You will have no claim(s) whatsoever against COQBOOK.
Third Party Games featured on our Website are provided by reputable developers and are tested and certified by independent testing laboratories to ensure fairness and accuracy of, among other things, the games’ random number generators (“RNG”) and the return to player percentages (“RTP”).
You acknowledge and agree that we have no control over the technical operation and/or functionality of third party provided games featured on the Website, and that we will not be held responsible, and will not be liable to You, in respect of any bug, error or defect in any game.
Individual games may be subject to maximum payouts, as detailed in the relevant Game Rules. You acknowledge and agree that it is Your responsibility to ensure that You understand the Game Rules for any game that You wish to play and that You are comfortable that a bet You place, if successful, may exceed the maximum payout as stated in the Game Rules.

Withdrawals
Due to the nature of the Services, and our ongoing anti money-laundering obligations, You are not entitled to a refund on any sums deposited to Your COQBOOK Account. You may, however, request a withdrawal of funds from Your COQBOOK Account if:
You are not in breach of these Terms ;
any identity or other verification checks that we are required to conduct pursuant to any applicable anti-money laundering regulations and/or other applicable legislation have been completed to our satisfaction;
You accept that all transactions may be checked to prevent money laundering and that any transactions made by You which we deem suspicious, may be reported to the appropriate authorities. We reserve the right to decline a withdrawal request and/or reverse the withdrawal of unspent or otherwise unused deposits until such time as the source of funds has been satisfactorily established; and
there is no ongoing investigation into:
a game defect involving a game which You have played and which requires a temporary freeze on withdrawal of funds; or
a breach by You of these Terms including, but not limited to, Your acting fraudulently, unlawfully or engaging in improper activity (including cheating, use of card counting mechanisms, collusion or criminal enterprise).
We may delay or cancel withdrawals in order to carry out additional checks which we may consider necessary, at our sole discretion, from time to time. We may refuse withdrawal requests and/or withhold funds in the event that we discover any irregularities in the course of our investigations.
There may be circumstances in which monies are paid to You in error. We will use all reasonable endeavours to detect any such errors and inform You of them as soon possible. In the event that You have been erroneously credited with any monies, You agree to hold those monies on trust for us and You will immediately repay the same to us upon request.
Whilst every effort is made to avoid mistakes when processing withdrawal requests, we will not be responsible or liable to You for Your errors or omissions when requesting to withdraw Your funds. In the event that any deposit or withdrawal request is processed incorrectly, it is Your responsibility to inform us and we will use all reasonable efforts to resolve the issue.
By default, we will process and authorise withdrawals via the same payment method(s) used for depositing funds to Your account; should this not be possible, for whatever reason, or should You wish to initiate a withdrawal to a method other than that which was used for depositing funds, we may request additional documentation from You to prove that You are the legal holder of the requested withdrawal address. Further, and in accordance with our regulatory obligations, we may delay and/or suspend withdrawals from Your account until such time as any such requests have been properly fulfilled to our sole satisfaction.
It is always Your responsibility to:
ensure that all information pertaining to any payment methods associated with Your COQBOOK Account is current and up-to-date; and You further acknowledge and agree that failure to do so may result in deposit and/or withdrawal requests being declined;
notify us if You believe that any of the payment methods associated with Your COQBOOK Account have been compromised, or if You have been a victim of any identify fraud which may impact upon such payment methods; upon notification, we will suspend Your account to prevent any further interaction with the Services until such time as You request otherwise.

Tax
Prior to Your use of the Service and on an ongoing basis You represent, warrant, covenant and agree that:
You are solely responsible for reporting and accounting for any taxes applicable to You under relevant laws for any winnings that You receive from us;
You are solely responsible for any applicable taxes which may be payable on cryptocurrency awarded to You through Your using the Service;

Bonuses
COQBOOK, at its sole discretion, might offer from time to time, a number of Rewards, Bonuses and Promotions.
Upon successful registration for a COQBOOK Account, You may be eligible to receive COQBOOK Rewards and Bonuses. By accepting this agreement and registering for a COQBOOK Account on the Website, You are also acknowledging and accepting to be bound by the rules and regulations associated with any Rewards, Promotions and Bonuses offered to You by COQBOOK.
All rewards, promotions, bonuses, or special offers are subject to the express terms of any bonus offered on the Website(s) and promotion-specific terms and conditions, if applicable, and any bonus credited to Your account must be used in adherence with such terms and conditions. By accepting a reward, promotion, bonus, or other special offer available on the Website(s), You consent to the terms and conditions of such offers. We reserve the right to withdraw any promotion, bonus, or special offer at any time.
By activating a Bonus or Promotion, You confirm that You also agree to the applicable terms and conditions.
No promotion, bonus, or special offer will be accepted or honoured by us following the expiration date of the promotion, bonus, or special offer, unless we in our sole discretion choose to do so for any particular customer, promotion, bonus, or special offer. Expiration dates will be set forth in the specific rules or terms and conditions of the particular promotion, bonus, or special offer. Furthermore, we reserve the right, in our sole discretion, to change or modify any policy with respect to the earning or expiration of bonuses.
Once forfeited or de-activated, the Bonus will no longer be available to You (and cannot be re-activated at any time thereafter). The amount of any Bonus Funds that have already been credited to Your COQBOOK Account Balance will remain available to You.
In the event that we believe a Player of the Service is abusing or attempting to abuse a bonus or other promotion or is likely to benefit through abuse or lack of good faith from a policy adopted by us, then we may, at our sole discretion, deny, withhold, or withdraw from any Player any bonus or promotion, or terminate that Player’s access to the Services, the Software, and/or lock that Player’s account, either temporarily or permanently.
The opening of multiple accounts on the Website (even if permitted by agreement as per Clause 4.2) for the purpose of accumulating bonuses, promotions, special offers, or otherwise, shall be considered abusive behaviour.
We reserve the right to remove bonuses from You if You are identified as  a “bonus abuser”.
We reserve the right to cancel all bonuses that have not been claimed within the claiming period.
We reserve the right to cancel any bonus at our sole discretion.

TERMS OF SERVICE & AUTHORITY
You agree to the game rules described on the COQBOOK.com website. COQBOOK retains authority over the issuing, maintenance, and closing of the Service. The decision of COQBOOK's management, concerning any use of the Service, or dispute resolution, is final and shall not be open to review or appeal.

PROHIBITED USES
COQBOOK expressly prohibits and rejects the use of the Service for any form of illicit activity, including money laundering, terrorist financing or trade sanctions violations, consistent with various jurisdictions' laws, regulations and norms. To that end, the Service is not offered to individuals or entities subject to United States, European Union, or other global sanctions or watch lists. By using the Service, You represent and warrant that You are not so subject.
Any attempt to circumvent the restrictions on play by any persons located in a Restricted Territory, is a breach of this Agreement. An attempt at circumvention includes, but is not limited to, manipulating the information used by COQBOOK to identify Your location and providing COQBOOK with false or misleading information regarding Your location or place of residence.
The attempt to manipulate Your real location through the use of VPN, proxy, or similar services or through the provision of incorrect or misleading information about Your place of residence, with the intent to circumvent geo-blocking or jurisdiction restrictions, constitutes a breach of this Agreement.

EQUIPMENT
Your computer equipment or mobile device and internet connection may affect the performance and/or operation of the Website.  We do not guarantee that the Website will operate without faults or errors or that the Services will be provided without interruption. We do not accept any liability for any failures or issues that arise due to Your equipment, internet connection or internet or telecommunication service provider (including, for example, if You are unable to place bets or wagers or to view or receive certain information in relation to particular events).
If You are using a mobile device for the placing of bets/wagers, please note that we will not be responsible for any damage to, or loss of data from the mobile device on which the Services are run, and will also not be responsible for any call, data or other charges incurred whilst using the Services.
Due to limited display sizes on mobile devices, the mobile experience might differ slightly from other platforms. Differences might include, but are not limited to, the location of certain information on the platform and game names not being visible on all game pages.

FAIR USE
The Website and Services may only be used for recreational purposes by placing bets and wagers on events and/or gaming products.
You must not use the Website for the benefit of a third party or for any purpose which is illegal, defamatory, abusive or obscene, or which we consider discriminatory, fraudulent, dishonest or inappropriate. We may report to the authorities any activity which it considers to be suspicious and/or in breach of this paragraph.
If we have a reasonable suspicion that You are involved in fraudulent, dishonest or criminal acts, as set out under applicable laws, via or in connection with the Website or Services, we may seek criminal and contractual sanctions against You. We will withhold payment to any customer where any of these are suspected or where the payment is suspected to be for the benefit of a third party.
You shall indemnify and shall be liable to pay us, on demand, all costs, charges or losses sustained or incurred by us and our affiliates (including any direct, indirect or consequential losses, loss of profit and loss of reputation) in respect of all Claims arising directly or indirectly from Your fraudulent, dishonest or criminal acts while using the Website or Services
Furthermore, we reserve the right not to accept, process and/or honour bets/wagers where it would be forbidden, unlawful or illegal under applicable law or regulation to do so.

SOFTWARE & TECHNOLOGY
In order for You to use the Website and Services, You may need to download some software (for example, casino games that are made available via a flash player). Also, certain third party product providers may require You to agree to additional terms and conditions governing the use of their products that are available on or through the Website. If You do not accept those third party terms and conditions, do not use the relevant third party software. COQBOOK does not accept any liability in respect of any third party software.
We hereby grant to You a personal, non-exclusive, non-transferable right to use the Website for the sole purpose of accessing and using the Services on the Website, in accordance with these Terms and Conditions. This right to use our Website and will be immediately terminated once Your user registration is cancelled for any reason, and specially, but not limited to, if You make use of that right with the aim of generating a parallel enterprise based on our Website or our products, or with the aim of making use of an automated service or software analysing, capturing or somehow using the information shown in our Website.
You are not permitted to:
install or load the software that forms part of the Website onto a server or other networked device or take other steps to make the software available via any form of "bulletin board", online service or remote network to any other person;
sub-license, assign, rent, lease, loan, transfer or copy (except as expressly provided elsewhere in these Terms and Conditions) Your right to use the Website, or the software that forms part of the Website, or make or distribute copies of same;
enter, access or attempt to enter or access or otherwise bypass COQBOOK’s security system or interfere in any way (including but not limited to, robots or similar devices) with the products or the Website or attempt to make any modifications to the software and/or any features or components thereof;
copy or translate any user documentation provided 'online' or in electronic format.
In addition, except to the minimum extent permitted by applicable law in relation to computer programs, You are not permitted to: (i) translate, reverse engineer, decompile, disassemble, modify, create derivative works based on, or otherwise modify the Website; or (ii) reverse engineer, decompile, disassemble, modify, adapt, translate, make any attempt to discover the source code of the software that forms part of the Website or to create derivative works based on the whole or on any part of the Website.
You do not own the software that forms part of the Website. Such software is owned and is the exclusive property of COQBOOK or a third party software provider company (any such third party provider, the "Software Provider"). Any software and accompanying documentation which have been licensed to COQBOOK are proprietary products of the Software Provider and protected throughout the world by copyright law. Your use of the software does not give You ownership of any intellectual property rights in the software.
The software is provided "as is" without any warranties, conditions, undertakings or representations, express or implied, statutory or otherwise. COQBOOK hereby excludes all implied terms, conditions and warranties, including any of merchantability, merchantable quality, satisfactory quality and fitness for any particular purpose, completeness or accuracy of the services or the software or infringement of applicable laws and regulations. COQBOOK does not warrant or condition that: (i) the software will meet Your requirements; (ii) the software will not infringe any third party’s intellectual property rights; (iii) the operation of the software will be error free or uninterrupted; (iv) any defects in the software will be corrected; or (v) the software or the servers are virus-free.
In the event of communications or system errors occurring in connection with the settlement of accounts or other features or components of the software, neither COQBOOK nor the Software Provider will have any liability to You or to any third party in respect of such errors. COQBOOK reserves the right in the event of such errors to remove all relevant products from the Website and take any other action to correct such errors.
You hereby acknowledge that how You use the software is outside of COQBOOK’s control. Accordingly, You install and/or use the software at Your own risk. COQBOOK will not have any liability to You or to any third party in respect of Your receipt of and/or use of the software.
The software may include confidential information which is secret and valuable to the Software Provider and/or COQBOOK. You are not entitled to use or disclose that confidential information other than strictly in accordance with these Terms and Conditions.
COQBOOK shall not be liable if for any reason the Website is unavailable at any time or for any period. We reserve the right to make changes or corrections to or to alter, suspend or discontinue any aspect of the Website and the content or services or products available through it, including Your access to it.
You must not misuse the Website by introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. In particular, You must not access the Website without authority, interfere with, damage or disrupt the Website or any part of it, any equipment or network on which the Website is hosted, any software used in connection with the provision of the Website, or any equipment, software or website owned or used by a third party. You must not attack our Website via a denial-of-service attack. We will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect Your computer equipment, computer programs, data or other proprietary material arising due to Your use of the Website, software or to Your downloading of any material posted on it, or on any website linked to it.

THIRD PARTY CONTENT
COQBOOK receives feeds, commentaries and content from a number of suppliers. Certain third party product providers may require You to agree to additional terms and conditions governing the use of their feeds, commentaries and content. If You do not accept the relevant third party terms and conditions, You agree to not use the relevant feeds, commentaries or content.
COQBOOK does not accept any liability in respect of any third party feeds, commentaries and content.
Where the Website contains links to third party websites and resources, these links are provided for Your information only. COQBOOK has no control over the content of these sites or resources, and accepts no liability for them or for any loss or damage that may arise from Your use of them. The inclusion of a link to a third party website does not constitute an endorsement of that third party’s website, product or services, if applicable.

ERRORS
We will not be liable in the event You try to or obtain an advantage from any errors in respect of bets or wagers on the Website if You were deliberately acting in bad-faith, including where: (i) there is an obvious error in the relevant odds, spreads, handicap, totals, cash-out; (ii) COQBOOK continues to accept bets or wagers on closed or suspended markets; (iii) COQBOOK incorrectly calculates or pays a settlement amount, including where a bet is Cashed Out for the full settlement amount, or a bet is made void incorrectly, where ‘Void if player does not start’ was selected at bet placement; or (iv) any error occurs in a random number generator or pay tables included, incorporated or used in any game or product.

BREACH

Without prejudice to any other rights, if You breach in whole or in part any provision contained herein, COQBOOK reserves the right to take such action as it sees fit, including terminating this Agreement or any other agreement in place with the User and/or taking legal action against You.
You agree to fully indemnify, defend and hold harmless COQBOOK and its shareholders, directors, agents and employees from and against all claims, demands, liabilities, damages, losses, costs and expenses, including legal fees and any other charges whatsoever, howsoever caused, that may arise as a result of:
Your breach of this Agreement, in whole or in part;
violation by You of any law or any third party rights; and
use by You of the Service.

LIMITATIONS & LIABILITY
Under no circumstances, including negligence, shall we be liable for any special, incidental, direct, indirect or consequential damages whatsoever (including, without limitation, damages for loss of business profits, business interruption, loss of business information, or any other pecuniary loss) arising out of the use (or misuse) of the Service even if we had prior knowledge of the possibility of such damages.
Nothing in this Agreement shall exclude or limit our liability for death or personal injury resulting from our negligence.

INTELLECTUAL PROPERTY
COQBOOK and its licensors are the sole holders of all rights in and to the Service and code, structure and organisation, including copyright, trade secrets, intellectual property and other rights. You may not, within the limits prescribed by applicable laws: (a) copy, distribute, publish, reverse engineer, decompile, disassemble, modify, or translate the website; or (b) use the Service in a manner prohibited by applicable laws or regulations (each of the above is an "Unauthorised Use"). COQBOOK reserves any and all rights implied or otherwise, which are not expressly granted to the User hereunder and retain all rights, title and interest in and to the Service. You agree that You will be solely liable for any damage, costs or expenses arising out of or in connection with the commission by You of any Unauthorized Use. You shall notify COQBOOK immediately upon becoming aware of the commission by any person of any Unauthorised Use and shall provide COQBOOK with reasonable assistance with any investigations it conducts in light of the information provided by You in this respect.
The term "COQBOOK", its domain names and any other trade marks, or service marks used by COQBOOK as part of the Service (the "Trade Marks"), are solely owned by COQBOOK. In addition, all content on the website, including, but not limited to, the images, pictures, graphics, photographs, animations, videos, music, audio and text (the "Site Content") belongs to COQBOOK and is protected by copyright and/or other intellectual property or other rights. You hereby acknowledge that by using the Service, You obtain no rights in the Site Content and/or the Trade Marks, or any part thereof. Under no circumstances may You use the Site Content and/or the Trade Marks without COQBOOK's prior written consent. Additionally, You agree not to do anything that will harm or potentially harm the rights, including the intellectual property rights of COQBOOK.

DISPUTES
If a User wishes to raise a dispute, please contact our compliance team at compliance@COQBOOK.com
Should any dispute not be resolved to Your satisfaction You may pursue remedies in the governing law jurisdiction set forth below.

AMENDMENT
COQBOOK reserves the right to update or modify this Agreement or any part thereof at any time or otherwise change the Service without notice and You will be bound by such amended Agreement upon posting. Therefore, we encourage You check the terms and conditions contained in the version of the Agreement in force at such time. Your continued use of the Service shall be deemed to attest to Your agreement to be bound by any such amendments, updates and/or modifications to the Agreement.


GOVERNING LAW
The Agreement and any matters relating hereto shall be governed by, and construed in accordance with, the laws of Curaçao. You irrevocably agree that, subject as provided below, the courts of Curaçao shall have exclusive jurisdiction in relation to any claim, dispute or difference concerning the Agreement and any matter arising therefrom and irrevocably waive any right that it may have to object to an action being brought in those courts, or to claim that the action has been brought in an inconvenient forum, or that those courts do not have jurisdiction. Nothing in this clause shall limit the right of COQBOOK to take proceedings against You in any other court of competent jurisdiction, nor shall the taking of proceedings in any one or more jurisdictions preclude the taking of proceedings in any other jurisdictions, whether concurrently or not, to the extent permitted by the law of such other jurisdiction.

SEVERABILITY
If a provision of this Agreement is or becomes illegal, invalid or unenforceable in any jurisdiction, that shall not affect the validity or enforceability in that jurisdiction of any other provision hereof or the validity or enforceability in other jurisdictions of that or any other provision hereof.

ASSIGNMENT
COQBOOK reserves the right to assign this agreement, in whole or in part, at any time without notice. The User may not assign any of his/her rights or obligations under this Agreement.

MISCELLANEOUS
No waiver by COQBOOK of any breach of any provision of this Agreement (including the failure of COQBOOK to require strict and literal performance of or compliance with any provision of this Agreement) shall in any way be construed as a waiver of any subsequent breach of such provision or of any breach of any other provision of this Agreement.
Nothing in this Agreement shall create or confer any rights or other benefits in favour of any third parties not party to this Agreement.
Nothing in this Agreement shall create or be deemed to create a partnership, agency, trust arrangement, fiduciary relationship or joint venture between You and COQBOOK.
COQBOOK may assign, transfer, charge, sub-license, or deal in any other manner with this Agreement, or sub-contract any of its rights and obligations under this Agreement, to any other party.
This Agreement constitutes the entire understanding and agreement between You and COQBOOK regarding the Service and supersedes any prior agreement, understanding, or arrangement between You and COQBOOK.

COMPLAINTS
If You have a complaint to make regarding our services, You may contact our customer support via our discord or twitter.

end
`,
  },
];

const formatText = (text: string) => {
  const formattedText = text
    .replace(/\n\n([^\n]+)\n/g, '<h3>$1</h3>') // wrap titles <h3>
    .replace(/<\/h3>([\s\S]*?)<h3>/g, '</h3><ul>$1</ul><h3>') // wrap text between titles in <ul>
    .replace(/<ul>([\s\S]*?)<\/ul>/g, (match, p1) => {
      // creating <li>
      const listItems = p1
        .replace(/:\n([\s\S]*?)\.\n/g, '<ul class="sublist">:\n$1</ul>.\n') // wrap sublists in <ul>
        .trim()
        .split(/[.;:]\n/)
        .map((line) => `<li class="list-item-faq">${line.trim()}</li>`)
        .join('');
      return `<ul class="list">${listItems}</ul>`;
    })
    .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;') // replacing tabs with HTML spaces
    .replace(/\n/g, '<br />') // replace \n with <br />
    .replace(/<h3>end<\/h3>/g, ''); // for numeration last title
  return <div dangerouslySetInnerHTML={{ __html: formattedText }}></div>;
};

const Support = () => {
  return (
    <PageWrapper title={'Support'}>
      <Box sx={{ marginTop: '42px', paddingLeft: '0px' }}>
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            sx={{
              backgroundColor: colors.graphite,
              color: '#fff',
              mb: '16px',
              textAlign: 'left',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
            >
              <Typography
                color={colors.pink}
                sx={{ fontSize: 18, fontWeight: 950 }}
              >
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>{formatText(faq.answer)}</AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </PageWrapper>
  );
};

export default Support;
