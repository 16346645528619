import React, { useState, createContext, ReactNode } from 'react';

interface KenoContextProps {
  winningCells: number[];
  setWinningCells: React.Dispatch<React.SetStateAction<number[]>>;
  selectedCells: number[];
  setSelectedCells: React.Dispatch<React.SetStateAction<number[]>>;
}

export const KenoContext = createContext<KenoContextProps>({
  winningCells: [],
  setWinningCells: () => {},
  selectedCells: [],
  setSelectedCells: () => {},
});

export const KenoProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [winningCells, setWinningCells] = useState<number[]>([]);
  const [selectedCells, setSelectedCells] = useState<number[]>([]);

  return (
    <KenoContext.Provider
      value={{ winningCells, setWinningCells, selectedCells, setSelectedCells }}
    >
      {children}
    </KenoContext.Provider>
  );
};
