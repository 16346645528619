import { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import GameCard, { GameCardBack } from '../../common/GameCard';
import SkipCardButton from './SkipCardButton';
import { HigherButton, LowerButton } from './GameButtons';
import ProfitField from './ProfitField';
import Footer from './Footer';
import ProfitModal from './ProfitModal';

import { gameFieldStyles } from '../../utils';
import { HiloContext } from '../../../../contexts/HiloContext';
import { colors } from '../../../../utils/colors';
import { swipeAnimations } from '../utils';

import lowest from '../../../../assets/hilo/lowest.svg';
import lowest_mobile from '../../../../assets/hilo/lowest_mobile.svg';
import highest from '../../../../assets/hilo/highest.svg';
import highest_mobile from '../../../../assets/hilo/highest_mobile.svg';

const GameField = ({ isTablet }: { isTablet: boolean }) => {
  const { currentCard, deck, gameActive, isSwiping, setCurrentCard } =
    useContext(HiloContext);

  useEffect(() => {
    if (!isSwiping) return;
    setTimeout(() => setCurrentCard({ ...currentCard, swiped: true }), 250);
  }, [isSwiping]);

  return (
    <Box sx={{ flexGrow: { xl: 1 } }}>
      <Box sx={gameFieldStyles}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: isTablet ? '16px 48px' : '48px',
            borderRadius: '8px',
            backgroundColor: colors.darkGrey,
            backgroundImage: `url(${isTablet ? highest_mobile : lowest}), url(${isTablet ? lowest_mobile : highest})`,
            backgroundPosition: `left ${isTablet ? 3 : 10}% center, right ${isTablet ? 3 : 10}% center`,
            backgroundRepeat: 'no-repeat, no-repeat',
            backgroundSize: isTablet ? '5%, 5%' : '10%, 10%',
          }}
        >
          <Box sx={{ position: 'relative', width: 'min-content' }}>
            {/* fake deck */}
            <Box>
              {[...new Array(5)].map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 0,
                    width: '100%',
                    height: '100%',
                    transform: `translate(0, ${10 - index * 2}%)`,
                  }}
                >
                  <GameCardBack cardSx={{ width: '120px', height: '190px' }} />
                </Box>
              ))}
            </Box>
            <SkipCardButton
              positionCoords={isTablet ? { left: -20 } : { right: -20 }}
            />
            <Box
              key={currentCard.id}
              sx={{
                opacity: currentCard.swiped ? 0 : 1,
                animation: `${isSwiping ? `${currentCard.color === 'red' ? swipeAnimations[0] : swipeAnimations[1]} 0.3s ease forwards` : ''}`,
              }}
            >
              <GameCard
                key={currentCard.id}
                cardSx={{ width: '120px', height: '190px', fontSize: '3.3em' }}
                card={currentCard}
                transform
                outline={
                  !gameActive &&
                  deck.length > 1 &&
                  (deck[deck.length - 1].payoutMultiplier || 0) === 0
                    ? '6px solid red'
                    : undefined
                }
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: isTablet ? 'flex' : 'none',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <HigherButton />
            <LowerButton />
          </Box>
        </Box>
        <ProfitField isTablet={isTablet} />
        <Footer isTablet={isTablet} />
        <ProfitModal />
      </Box>
    </Box>
  );
};

export default GameField;
