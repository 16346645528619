import { Box, useMediaQuery, useTheme } from '@mui/material';

import Cell from './Cell';
import Footer from './Footer';

import { gameFieldStyles } from '../../utils';

const cellsAmount = 40;
const maxSelectedCells = 10;

const KenoGrid = ({ isLG }: { isLG: boolean }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: isLG ? 'auto' : '100%',
        aspectRatio: '8 / 5.2',
        maxHeight: '600px',
        margin: 'auto',
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(8, 1fr)',
          gap: { xs: '4px', md: '8px', lg: '10px' },
        }}
      >
        {Array.from({ length: cellsAmount }, (_, i) => (
          <Cell
            number={i + 1}
            maxSelectedCells={maxSelectedCells}
            key={`gencell${i}`}
          />
        ))}
      </Box>
    </Box>
  );
};

const GameField = () => {
  const theme = useTheme();
  const isLG = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box sx={{ flexGrow: { xl: 1 }, width: '100%' }}>
      <Box sx={gameFieldStyles}>
        <Box
          sx={{
            maxWidth: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <KenoGrid isLG={isLG} />
          <Footer maxSelectedCells={maxSelectedCells} />
        </Box>
      </Box>
    </Box>
  );
};

export default GameField;
