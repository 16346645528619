import { Box, TextField, InputAdornment, Typography } from '@mui/material';
import { colors } from '../../../../utils/colors';

const ProfitField = ({ profitValue }: { profitValue: number }) => (
  <Box sx={{ width: '100%', paddingY: '0px' }}>
    <TextField
      value={profitValue.toFixed(2)}
      disabled
      id="outlined-basic"
      variant="outlined"
      type="number"
      autoComplete="off"
      InputLabelProps={{
        style: { color: colors.freshWhite },
      }}
      sx={{
        marginY: '16px',
        color: colors.freshWhite,
        width: '100%',
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            border: `1px solid ${colors.borderGrey}`,
            borderRadius: '8px',
          },
          '&:hover fieldset': {
            border: `1px solid ${colors.borderHover}`,
            transition: 'border-color 0.3s ease-in-out',
          },
          '&.Mui-focused fieldset': {
            border: `2px solid ${colors.borderHover}`,
          },
          '& .MuiInputBase-input': {
            color: colors.freshWhite,
          },
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: colors.freshWhite,
          },
        },
        '& .MuiOutlinedInput-root.Mui-disabled': {
          '& fieldset': {
            border: `1px solid ${colors.borderGrey}`,
          },
          '& hover fieldset': {
            border: `1px solid ${colors.borderGrey}`,
          },
        },
      }}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end" sx={{ color: '#fff' }}>
            <Typography sx={{ color: '#fff' }}>MCOQ</Typography>
          </InputAdornment>
        ),
      }}
    />
  </Box>
);

export default ProfitField;
