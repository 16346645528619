import { BarChart, ContentCopy, Link as LinkIcon } from '@mui/icons-material';
import { Box, Card, Typography } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { BETMODE, BETSETTLEMENT, TOASTTYPE } from '../../utils/config';
import { conversionOdd } from '../../utils/conversion.util';
import * as format from '../../utils/format.util';
import { showToast } from '../../utils/toast';

import logo from '../../assets/images/coqbook_logo.svg';
import CurrencyFormatter from '../CurrencyFormatter';
import SportIcon from '../SportIcon';

const styles = {
  blurTxt: { color: '#fff', fontSize: 12, fontWeight: 500, opacity: 0.4 },
  boldTxt: { color: '#fff', fontSize: 12, fontWeight: 700 },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  sideby: { display: 'flex', alignItems: 'center', gap: 1 },
};

const BetCard = (props) => {
  const location = useLocation();
  const oddFormat = 'european odds';

  const bet = props.data || props.bet;

  const calculatePotentialWin = (bet) => {
    return bet.stake * calculateTotalOdds(bet);
  };

  const calculateTotalOdds = (bet) => {
    return bet.components?.reduce((acc, cur) => {
      if (cur?.settlement !== 'refund') {
        return acc * cur?.outcome?.odds;
      }
      return acc;
    }, 1);
  };

  const handleCopyLink = () => {
    const url = `${window.location.origin}${location.pathname}?betId=${bet.id}&modal=bet`;
    navigator.clipboard.writeText(url).then(() => {
      showToast(TOASTTYPE.success, 'Link copied!', { autoClose: 1000 });
    });
  };

  return (
    <Card
      sx={{
        backgroundColor: '#0f0f0f',
        backgroundImage:
          'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
        p: 2,
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        height: '100%',
        boxSizing: 'border-box',
        color: '#fff',
      }}
    >
      <Box sx={styles.row}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Typography sx={{ ...styles.boldTxt, textTransform: 'uppercase' }}>
            {bet.type}
          </Typography>
          <Typography sx={styles.blurTxt}>
            {format.date(bet.createdTime)}
          </Typography>
        </Box>
        <Typography
          color={BETSETTLEMENT[bet.settlement]?.settlementColor}
          sx={{ textTransform: 'uppercase', fontSize: 12 }}
        >
          {bet.settlement ?? 'open'}
        </Typography>
      </Box>
      {bet.components?.map((component) => (
        <span key={component.id}>
          <Box
            sx={{
              backgroundColor: 'rgb(28, 35, 39)',
              p: 1,
              borderRadius: 1,
              display: 'flex',
              gap: 1,
              flexDirection: 'column',
              color: '#fff',
            }}
          >
            <Box sx={styles.row}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  opacity: 0.6,
                  gap: 1,
                  '&:hover': {
                    opacity: 1,
                  },
                }}
              >
                <SportIcon name={component?.event?.competition?.sport?.name} />
                <Typography
                  sx={{ fontSize: 12 }}
                >{`${component?.event?.competition.name} > ${component?.market?.name}`}</Typography>
              </Box>
              {bet.type === BETMODE.combo && (
                <Typography
                  color={BETSETTLEMENT[component.settlement].settlementColor}
                  sx={{ textTransform: 'uppercase', fontSize: 12 }}
                >
                  {BETSETTLEMENT[component.settlement]?.text}
                </Typography>
              )}
            </Box>
            <Box sx={styles.row}>
              <Box sx={styles.sideby}>
                <Typography sx={{ fontSize: 12 }}>
                  {component?.event?.team1}
                </Typography>
                <Typography sx={styles.blurTxt}>VS</Typography>
                <Typography sx={{ fontSize: 12 }}>
                  {component?.event?.team2}
                </Typography>
              </Box>
              <BarChart sx={{ opacity: 0.5 }} />
            </Box>
            <Box sx={styles.row}>
              <Typography sx={{ fontSize: 12, opacity: 0.6 }}>
                {component?.outcome?.name}
              </Typography>
              <Typography sx={{ fontSize: 12, opacity: 0.6 }}>
                {conversionOdd(component?.outcome?.odds, oddFormat)}
              </Typography>
            </Box>
          </Box>
        </span>
      ))}
      <Box sx={{ ...styles.row, gap: 2 }}>
        <Box sx={styles.sideby}>
          <Typography
            sx={{
              fontSize: 12,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              width: 120,
            }}
          >{`Ticket ID: ${bet.id}`}</Typography>
          <ContentCopy
            sx={{ fontSize: 14, cursor: 'pointer' }}
            onClick={() => {
              navigator.clipboard.writeText(bet.id).then(() => {
                showToast(TOASTTYPE.success, 'Copied!', { autoClose: 1000 });
              });
            }}
          />
          <LinkIcon
            sx={{ fontSize: 18, cursor: 'pointer' }}
            onClick={handleCopyLink}
          />
        </Box>
        <Box component="img" alt="logo" sx={{ width: 100 }} src={logo} />
      </Box>
      {bet.type === BETMODE.single ? (
        <Box sx={{ ...styles.row, mt: 1 }}>
          <Typography sx={styles.blurTxt}>
            {bet.components?.[0]?.outcome?.name}
          </Typography>
          <Typography sx={styles.blurTxt}>
            {conversionOdd(bet.components?.[0]?.outcome?.odds, oddFormat)}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ ...styles.row, mt: 1 }}>
          <Typography sx={styles.blurTxt}>TOTAL ODDS</Typography>
          <Typography sx={styles.blurTxt}>
            {conversionOdd(
              format
                .number()
                .format(bet.statistics?.totalOdds || calculateTotalOdds(bet)),
              oddFormat,
            )}
          </Typography>
        </Box>
      )}
      <Box sx={styles.row}>
        <Typography sx={styles.blurTxt}>Stake</Typography>
        <Typography sx={styles.blurTxt}>
          <CurrencyFormatter value={bet.stake} />
        </Typography>
      </Box>
      {!['lost', 'refund'].includes(bet.settlement) && (
        <Box sx={styles.row}>
          <Typography sx={{ ...styles.boldTxt, textTransform: 'uppercase' }}>
            {BETSETTLEMENT[bet.settlement]?.text}
          </Typography>
          <Typography sx={styles.boldTxt}>
            <CurrencyFormatter
              value={
                bet.statistics?.actualWin ||
                bet.statistics?.potentialWin ||
                calculatePotentialWin(bet)
              }
            />
          </Typography>
        </Box>
      )}
    </Card>
  );
};

export default React.memo(BetCard, (prevProps, nextProps) => {
  return prevProps.bet.id === nextProps.bet.id;
});
