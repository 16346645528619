import React from 'react';
import { Box, Card, Skeleton } from '@mui/material';

const BetCardSkeleton = () => {
  return (
    <Card
      sx={{
        backgroundColor: '#0f0f0f',
        backgroundImage:
          'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
        p: 2,
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        height: '100%',
        boxSizing: 'border-box',
        color: '#fff',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Skeleton variant="text" width={100} height={20} />
        <Skeleton variant="text" width={50} height={20} />
      </Box>

      <Box
        sx={{
          backgroundColor: 'rgb(28, 35, 39)',
          p: 1,
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          color: '#fff',
        }}
      >
        <Skeleton variant="text" width="80%" height={20} />
        <Skeleton variant="text" width="60%" height={20} />
        <Skeleton variant="text" width="40%" height={20} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 2,
          alignItems: 'center',
        }}
      >
        <Skeleton variant="text" width={120} height={20} />
        <Skeleton variant="rectangular" width={100} height={40} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 1,
        }}
      >
        <Skeleton variant="text" width="40%" height={20} />
        <Skeleton variant="text" width="40%" height={20} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Skeleton variant="text" width="30%" height={20} />
        <Skeleton variant="text" width="30%" height={20} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Skeleton variant="text" width="40%" height={20} />
        <Skeleton variant="text" width="40%" height={20} />
      </Box>
    </Card>
  );
};

export default BetCardSkeleton;
