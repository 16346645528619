import { useState, useContext, useEffect } from 'react';
import Typography from '@mui/material/Typography';

import '@rainbow-me/rainbowkit/styles.css';
import { useReadContract, useSimulateContract, useWriteContract } from 'wagmi';
import { formatUnits, parseUnits, erc20Abi } from 'viem';
import httpClient from '../../../utils/http-client';
import { showToast, TOASTTYPE } from '../../../utils/toast';
import {
  COQ_CONTRACT_ADDRESS,
  HOUSE_CONTRACT_ADDRESS,
} from '../../../utils/config';
import { UserDetailsContext } from '../../../contexts/UserContext';
import { buttonStyle, hoverStyle, textStyle, customConfig } from '../utils';

import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { InputAdornment, IconButton } from '@mui/material';
import houseAbi from '../../../abis/house.abi';
import { keyframes } from '@emotion/react';

import ManualDeposit from './ManualDeposit';

export default function DepositWithdrawalInsert(props) {
  const [modalView, setModalView] = useState('Deposit');
  const [amount, setAmount] = useState('0');
  const [isLoading, setLoading] = useState(false);
  const [mcoqBalance, setMcoqBalance] = useState(0);
  const readableAmount = Number(amount).toString();
  const mcoqBalanceRoundedDown = Math.floor(mcoqBalance * 100) / 100;
  const { balance, address } = useContext(UserDetailsContext);

  const { logoutAction = () => {}, handleTransaction = () => {} } = props;

  const { data: allowance, refetch: refetchAllowance } = useReadContract({
    address: COQ_CONTRACT_ADDRESS,
    abi: erc20Abi,
    functionName: 'allowance',
    args: [(address as `0x${string}`) || '0x', HOUSE_CONTRACT_ADDRESS],
  });

  const { data } = useSimulateContract({
    address: COQ_CONTRACT_ADDRESS,
    abi: erc20Abi,
    functionName: 'approve',
    args: [HOUSE_CONTRACT_ADDRESS, parseUnits(amount, 24)],
  });

  const { data: depositData, refetch: refetchDeposit } = useSimulateContract({
    address: HOUSE_CONTRACT_ADDRESS,
    abi: houseAbi,
    functionName: 'deposit',
    args: [parseUnits(amount, 24)],
  });

  const { writeContractAsync } = useWriteContract();

  const balanceOfData = useReadContract({
    address: COQ_CONTRACT_ADDRESS,
    abi: erc20Abi,
    functionName: 'balanceOf',
    args: [(address as `0x${string}`) || '0x'],
  });

  useEffect(() => {
    if (!balanceOfData.data) return;
    setMcoqBalance(Number(formatUnits(balanceOfData.data, 24)));
  }, [balanceOfData]);

  const doApproval = async () => {
    setLoading(true);
    await handleTransaction();
    const hash = await writeContractAsync(data!.request).catch((e) =>
      console.error(e),
    );

    if (hash) {
      showToast(TOASTTYPE.success, 'Approval successful.');
      for (let i = 0; i < 16; i++) {
        setTimeout(
          () => {
            refetchAllowance();
            refetchDeposit();
          },
          1000 + i * 500,
        );
      }
    } else {
      showToast(TOASTTYPE.error, 'Approval failed.');
    }
    setLoading(false);
  };

  const doDeposit = async () => {
    setLoading(true);
    await handleTransaction();
    try {
      const hash = await writeContractAsync(depositData!.request).catch((e) =>
        console.error(e),
      );

      if (hash) {
        for (let i = 0; i < 5; i++) {
          await new Promise((resolve) => setTimeout(resolve, 2000));

          try {
            const res = await httpClient.post(
              '/user/deposit',
              {
                address,
                txnHash: hash,
                chainId: depositData!.chainId,
              },
              customConfig,
            );

            if (res.status === 201) {
              showToast(TOASTTYPE.success, 'Successful deposit!');
              refetchAllowance();
              break;
            }
          } catch {}
        }
      } else {
        showToast(TOASTTYPE.error, 'Deposit failed.');
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const needsApproval = (allowance || 0n) < parseUnits(amount, 24);
  const invalidAmount = amount === '0' || amount === '';

  const doWithdraw = async () => {
    setLoading(true);
    try {
      const res = await httpClient.post(
        '/user/withdraw',
        {
          amount: Math.floor(Number(amount)).toString(),
          chainId: 43114,
        },
        customConfig,
      );
      if (res.status === 201) {
        showToast(TOASTTYPE.success, 'Successful withdraw!');
      }
    } catch (e: any) {
      showToast(TOASTTYPE.error, e.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const animationKeyframes = keyframes`
    0% {
      background-color: rgba(236, 95, 89, 1);
    }
    50% {
      background-color: rgba(236, 95, 89, 0.5);
    }
    99% {
      background-color: rgba(236, 95, 89, 1);
    }
  `;

  const loadingAnimation = isLoading
    ? `2.5s linear ${animationKeyframes} infinite`
    : '';

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      !isNaN(Number(event.target.value))
        ? setAmount(event.target.value)
        : console.log('bad numerical input');
    } catch {
      setAmount('0');
    }
  };

  const handleMaxClick = () => {
    if (modalView === 'Deposit') {
      setAmount(mcoqBalanceRoundedDown.toString());
    } else {
      setAmount(Math.floor(Number(balance.split(' ')[0])).toString());
    }
  };

  const formatter = new Intl.NumberFormat('en-EN', {});

  function CoqConversionText() {
    return (
      <Typography align="center" sx={{ ...textStyle }}>
        {readableAmount} MCOQ is{' '}
        {formatter.format(parseFloat(readableAmount) * Math.pow(10, 6))} COQ
      </Typography>
    );
  }

  return (
    <Stack spacing={2.5}>
      <ButtonGroup
        variant="contained"
        aria-label="Basic button group"
        fullWidth={true}
      >
        <Button
          sx={{
            '&:hover': hoverStyle,
            backgroundColor: modalView === 'Deposit' ? '#F00' : '#ec5f59',
            m: 0,
            p: 1,
          }}
          onClick={() => setModalView('Deposit')}
        >
          <Typography sx={{ ...textStyle }}>Deposit</Typography>
        </Button>
        <Button
          sx={{
            '&:hover': hoverStyle,
            backgroundColor: modalView === 'Withdraw' ? '#F00' : '#ec5f59',
            m: 0,
            p: 1,
          }}
          onClick={() => setModalView('Withdraw')}
        >
          <Typography sx={{ ...textStyle }}>Withdraw</Typography>
        </Button>
      </ButtonGroup>
      <TextField
        id="outlined-basic"
        label="Amount"
        value={amount}
        variant="outlined"
        autoComplete="off"
        onChange={handleTextChange}
        sx={{
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: 'primary.dark', // border color when hovered
            },
          },
          input: { color: 'secondary.main' },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                sx={{
                  ...textStyle,
                  ...buttonStyle,
                  '&:hover': hoverStyle,
                  height: 'min-content',
                  padding: '6px 8px',
                }}
                onClick={handleMaxClick}
              >
                MAX
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {modalView === 'Deposit' ? (
        <>
          <CoqConversionText />
          <Box>
            <Typography align="center" sx={{ ...textStyle }}>
              MCOQ Allowance: {formatUnits(allowance || 0n, 24)} MCOQ
            </Typography>
            <Typography align="center" sx={{ ...textStyle }}>
              Wallet balance: {mcoqBalanceRoundedDown} MCOQ
            </Typography>
            <ManualDeposit />
          </Box>
          {needsApproval ? (
            <Button
              sx={{
                ...buttonStyle,
                '&:hover': hoverStyle,
                animation: loadingAnimation,
              }}
              disabled={invalidAmount || !mcoqBalance}
              onClick={async () => await doApproval()}
            >
              <Typography sx={{ ...textStyle }}>
                {isLoading ? (
                  <>Approving...</>
                ) : (
                  <>Approve {readableAmount} MCOQ</>
                )}
              </Typography>
            </Button>
          ) : (
            <Button
              sx={{
                ...buttonStyle,
                '&:hover': hoverStyle,
                animation: loadingAnimation,
              }}
              disabled={invalidAmount || !mcoqBalance}
              onClick={async () => await doDeposit()}
            >
              <Typography sx={textStyle}>
                {isLoading ? (
                  <>Depositing {readableAmount} MCOQ...</>
                ) : (
                  <>Deposit {readableAmount} MCOQ</>
                )}
              </Typography>
            </Button>
          )}
        </>
      ) : (
        <>
          <CoqConversionText />
          <Typography align="center" sx={textStyle}>
            Withdrawable balance: {balance}
          </Typography>
          <Button
            sx={{
              ...buttonStyle,
              '&:hover': hoverStyle,
              animation: loadingAnimation,
            }}
            disabled={invalidAmount}
            onClick={() => doWithdraw()}
          >
            <Typography sx={textStyle}>
              {isLoading ? (
                <>Widthdraw {readableAmount} MCOQ...</>
              ) : (
                <>Withdraw {readableAmount} MCOQ</>
              )}
            </Typography>
          </Button>
        </>
      )}
      <Button
        sx={{ ...buttonStyle, '&:hover': hoverStyle }}
        onClick={logoutAction}
      >
        <Typography sx={textStyle}>Disconnect</Typography>
      </Button>
    </Stack>
  );
}
