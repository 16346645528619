import { Card } from '../contexts/HiloContext';
import { getProbabilityAndMultiplier } from '../utils/getHiloProbabiltyMultiplier';

const cardRanks = {
  '2': 0,
  '3': 1,
  '4': 2,
  '5': 3,
  '6': 4,
  '7': 5,
  '8': 6,
  '9': 7,
  '10': 8,
  J: 9,
  Q: 10,
  K: 11,
  A: -1,
};

let fakeState = {
  id: 'aa7c4411-6646-4bdb-b22b-be6d672786f8',
  active: false,
  payoutMultiplier: 0,
  amountMultiplier: 1,
  amount: 0,
  payout: 0,
  updatedAt: 'Thu, 22 Aug 2024 17:26:06 GMT',
  currency: 'mcoq',
  game: 'hilo',
  state: {
    startCard: {
      suit: 'S',
      rank: '9',
    },
    rounds: [
      {
        card: {
          suit: 'S',
          rank: '3',
        },
        guess: 'skip',
        payoutMultiplier: 1,
      },
    ],
  },
};

const generateCard = (): Card => {
  const ranks: Card['rank'][] = [
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    'J',
    'Q',
    'K',
    'A',
  ];
  const suits: Card['suit'][] = ['♠', '♡', '♢', '♣']; // TODO: replace to assets

  const rank = ranks[Math.floor(Math.random() * ranks.length)];
  const suit = suits[Math.floor(Math.random() * suits.length)];
  const color = suit === '♠' || suit === '♣' ? 'black' : 'red';
  const id = 'hilo-card-' + Math.random().toString(36);
  const side = 'back';

  return { rank, suit, color, id, side };
};

export const activeGame = async () => {
  await new Promise((resolve) => setTimeout(resolve, Math.random() * 1000));
  const game = JSON.parse(
    window.localStorage.getItem('game') || JSON.stringify(fakeState),
  );
  if (game.active) {
    fakeState = game;
  }
  return fakeState;
};

export const startGame = async (card: Card) => {
  await new Promise((resolve) => setTimeout(resolve, Math.random() * 1000));
  fakeState.active = true;
  fakeState.state.startCard = {
    suit: card.suit,
    rank: card.rank,
  };
  fakeState.state.rounds = [];
  window.localStorage.setItem('game', JSON.stringify(fakeState));
  return fakeState;
};

export const skipCard = async () => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  const card = generateCard();
  const rounds = fakeState.state.rounds;
  const multiplier =
    rounds.length > 0 ? rounds[rounds.length - 1].payoutMultiplier : 1;

  fakeState.state.rounds.push({
    card: {
      suit: card.suit,
      rank: card.rank,
    },
    guess: 'skip',
    payoutMultiplier: multiplier,
  });
  localStorage.setItem('game', JSON.stringify(fakeState));
  return fakeState;
};

export const higher = async () => {
  await new Promise((resolve) => setTimeout(resolve, Math.random() * 1000));

  const rounds = fakeState.state.rounds;
  const { multiplier } = getProbabilityAndMultiplier(
    rounds.length > 0
      ? rounds[rounds.length - 1].card.rank
      : fakeState.state.startCard.rank,
    'higher',
  );

  const card = generateCard();
  const newMultiplier =
    rounds.length > 0
      ? rounds[rounds.length - 1].payoutMultiplier * multiplier
      : 1 * multiplier;

  fakeState.state.rounds.push({
    card: {
      suit: card.suit,
      rank: card.rank,
    },
    guess: 'higher',
    payoutMultiplier: newMultiplier,
  });

  if (
    cardRanks[
      fakeState.state.rounds.length > 1
        ? fakeState.state.rounds.slice(-2)[0].card.rank
        : fakeState.state.startCard.rank
    ] <= cardRanks[card.rank]
  ) {
    localStorage.setItem('game', JSON.stringify(fakeState));
    return fakeState;
  }
  fakeState.state.rounds[fakeState.state.rounds.length - 1].payoutMultiplier =
    0;
  fakeState.active = false;
  localStorage.removeItem('game');
  return fakeState;
};

export const lower = async () => {
  await new Promise((resolve) => setTimeout(resolve, Math.random() * 1000));
  const rounds = fakeState.state.rounds;
  const { multiplier } = getProbabilityAndMultiplier(
    rounds.length > 0
      ? rounds[rounds.length - 1].card.rank
      : fakeState.state.startCard.rank,
    'lower',
  );

  const card = generateCard();
  const newMultiplier =
    rounds.length > 0
      ? rounds[rounds.length - 1].payoutMultiplier * multiplier
      : 1 * multiplier;

  fakeState.state.rounds.push({
    card: {
      suit: card.suit,
      rank: card.rank,
    },
    guess: 'lower',
    payoutMultiplier: newMultiplier,
  });
  if (
    cardRanks[
      fakeState.state.rounds.length > 1
        ? fakeState.state.rounds.slice(-2)[0].card.rank
        : fakeState.state.startCard.rank
    ] >= cardRanks[card.rank]
  ) {
    localStorage.setItem('game', JSON.stringify(fakeState));
    return fakeState;
  }
  fakeState.state.rounds[fakeState.state.rounds.length - 1].payoutMultiplier =
    0;
  fakeState.active = false;
  localStorage.removeItem('game');
  return fakeState;
};

export const cashout = async () => {
  await new Promise((resolve) => setTimeout(resolve, Math.random() * 1000));
  fakeState.active = false;
  fakeState.payoutMultiplier =
    fakeState.state.rounds[fakeState.state.rounds.length - 1].payoutMultiplier;
  window.localStorage.removeItem('game');
  return fakeState;
};
