import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Typography } from '@mui/material';

const navigateButtons = [
  // { to: '/profile', icon: <AccountCircleIcon />, label: 'Profile' },
  { to: '/transactions', icon: <ReceiptIcon />, label: 'Transactions' },
  { to: '/bethistory', icon: <HistoryIcon />, label: 'Bet History' },
  { to: '/settings', icon: <SettingsIcon />, label: 'Settings' },
];

const Navigation = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        p: 0,
        overflowX: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}
    >
      {navigateButtons.map((button) => (
        <Link
          to={button.to}
          key={button.label}
          style={{ textDecoration: 'none' }}
        >
          <Button
            startIcon={button.icon}
            variant="outlined"
            sx={{
              color: '#fff',
              borderColor: '#ec5f59',
              backgroundColor:
                document.location.pathname === button.to
                  ? '#ec5f59'
                  : 'transparent',
              borderRadius: '7px',
              textTransform: 'none',
              width: 'max-content',
            }}
          >
            <Typography sx={{ color: '#fff', fontWeight: 700 }}>
              {button.label}
            </Typography>
          </Button>
        </Link>
      ))}
    </Box>
  );
};

export default Navigation;
