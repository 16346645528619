import { Box, useMediaQuery, useTheme } from '@mui/material';

import Cell from './Cell';
import CashoutModal from './CashoutModal';

import { MinesContext } from '../../../../contexts/MinesContext';
import { useContext } from 'react';
import { gameFieldStyles } from '../../utils';

const MinesGrid = ({ isLG }: { isLG: boolean }) => {
  const { gridSize } = useContext(MinesContext);

  return (
    <Box
      sx={{
        position: 'relative',
        width: isLG ? 'auto' : '100%',
        height: isLG ? '100%' : 'auto',
        aspectRatio: '1 / 1',
        maxHeight: '600px',
        maxWidth: '600px',
        margin: 'auto',
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: `repeat(${gridSize ** 0.5}, 1fr)`,
          gap: { xs: '4px', md: '8px', lg: '10px' },
        }}
      >
        {Array.from({ length: gridSize }, (_, i) => (
          <Cell number={i + 1} key={`gencell${i}`} />
        ))}
      </Box>
    </Box>
  );
};

const GameField = () => {
  const theme = useTheme();
  const isLG = useMediaQuery(theme.breakpoints.up('lg'));
  const { isActive, currentMultiplier } = useContext(MinesContext);

  return (
    <Box sx={{ flexGrow: { xl: 1 }, position: 'relative', width: '100%' }}>
      <Box sx={gameFieldStyles}>
        <MinesGrid isLG={isLG} />
      </Box>
      {!isActive && currentMultiplier > 0 && <CashoutModal />}
    </Box>
  );
};

export default GameField;
