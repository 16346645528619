const KENO_MULTIPLIERS = {
  CLASSIC: {
    '1': [0, 3.92],
    '2': [0, 1.8, 5],
    '3': [0, 1, 3, 10.6],
    '4': [0, 0.8, 1.75, 5, 23],
    '5': [0, 0.25, 1.4, 4, 16, 40],
    '6': [0, 0, 1, 3.6, 7, 16.5, 40],
    '7': [0, 0, 0.44, 3, 4.5, 14, 30, 60],
    '8': [0, 0, 0, 2.2, 4, 12, 25, 55, 70],
    '9': [0, 0, 0, 1.5, 3, 8, 16, 44, 60, 85],
    '10': [0, 0, 0, 1.4, 2.2, 4.5, 7, 20, 50, 80, 100],
  },
  HIGH: {
    '1': [0, 3.92],
    '2': [0, 0, 17],
    '3': [0, 0, 0, 81.5],
    '4': [0, 0, 0, 10, 255],
    '5': [0, 0, 0, 4.4, 48, 450],
    '6': [0, 0, 0, 0, 10, 350, 950],
    '7': [0, 0, 0, 0, 6.5, 90, 435, 950],
    '8': [0, 0, 0, 0, 4.8, 20, 270, 700, 1000],
    '9': [0, 0, 0, 0, 3.8, 11, 56, 560, 850, 1200],
    '10': [0, 0, 0, 0, 3.4, 8, 13, 70, 560, 1000, 1200],
  },
  LOW: {
    '1': [0.6, 2.12],
    '2': [0, 1.95, 4],
    '3': [0, 1.05, 1.55, 25],
    '4': [0, 0, 2.1, 8, 93],
    '5': [0, 0, 1.4, 4.45, 13, 300],
    '6': [0, 0, 1.05, 2, 6.5, 100, 700],
    '7': [0, 0, 1.05, 1.6, 3.7, 15, 225, 700],
    '8': [0, 0, 1.05, 1.5, 2, 6, 39, 100, 800],
    '9': [0, 0, 1.05, 1.3, 1.7, 2.5, 9, 50, 250, 1000],
    '10': [0, 0, 1.05, 1.2, 1.3, 2, 3.5, 13, 50, 250, 1000],
  },
  MEDIUM: {
    '1': [0.35, 2.87],
    '2': [0, 1.75, 5.3],
    '3': [0, 0, 2.64, 51],
    '4': [0, 0, 1.65, 10, 101],
    '5': [0, 0, 1.35, 4, 14, 400],
    '6': [0, 0, 0, 2.9, 9, 180, 800],
    '7': [0, 0, 0, 1.9, 7, 30, 420, 800],
    '8': [0, 0, 0, 1.9, 4, 12, 67, 400, 900],
    '9': [0, 0, 0, 1.9, 2.5, 5.6, 15, 100, 500, 1000],
    '10': [0, 0, 0, 1.55, 2, 4, 7.5, 30, 100, 500, 1000],
  },
};

export const getMultiplier = (risk: string, selectedCells: number) => {
  return KENO_MULTIPLIERS[risk][selectedCells.toString()];
};
