import { createContext, useState } from 'react';

type BetStreamContextType = {
  address: string;
  setAddress: (address: string) => void;
  betId: string;
  setBetId: (betId: string) => void;
};

export const BetStreamContext = createContext<BetStreamContextType>({
  address: '',
  betId: '',
  setAddress: () => {},
  setBetId: () => {},
});

export function BetStreamProvider({ children }: any) {
  const [address, setAddress] = useState('');
  const [betId, setBetId] = useState('');

  return (
    <BetStreamContext.Provider
      value={{
        address,
        setAddress,
        betId,
        setBetId,
      }}
    >
      {children}
    </BetStreamContext.Provider>
  );
}
