import React from 'react';
import { Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';

import { colors } from '../../../utils/colors';

const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

const LoaderDot = styled(Box)(({ theme }) => ({
  width: '0.75rem',
  height: '0.75rem',
  margin: '0 0.125rem',
  borderRadius: '50%',
  backgroundColor: '#1d1b29',
  animation: `${bounce} 1.4s infinite ease-in-out both`,
  '&.first': {
    animationDelay: '-0.32s',
  },
  '&.last': {
    animationDelay: '-0.16s',
  },
}));

const LoaderContainer = styled(Box)({
  position: 'absolute',
  inset: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colors.pink,
});

const Loader = () => {
  return (
    <LoaderContainer>
      <LoaderDot className="first" />
      <LoaderDot />
      <LoaderDot className="last" />
    </LoaderContainer>
  );
};

export default Loader;
