import { Button, styled } from '@mui/material';
import { colors } from '../../../utils/colors';

const BetButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  padding: '12px 14px',
  overflow: 'hidden',
  textTransform: 'none',
  color: '#000',
  backgroundColor: colors.pink,
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: colors.transparentPink,
  },
  '&:disabled': {
    backgroundColor: colors.neutral700,
    color: colors.freshWhite,
  },
}));

export default BetButton;
