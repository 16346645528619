import { useContext, useEffect, useRef, useState } from 'react';
import { Box, Slide } from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';

import GameCard from '../../common/GameCard';
import { HiloContext } from '../../../../contexts/HiloContext';
import { colors } from '../../../../utils/colors';

const Footer = ({ isTablet }: { isTablet: boolean }) => {
  const { deck, isSwiping } = useContext(HiloContext);
  const [isAnimationFinished, setIsAnimationFinished] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      containerRef.current &&
      !isSwiping &&
      isAnimationFinished &&
      deck.length > 1
    ) {
      containerRef.current.scrollLeft = containerRef.current.scrollWidth;
      setIsAnimationFinished(false);
    }
  }, [deck, isSwiping, isAnimationFinished]);

  return (
    <Box
      ref={containerRef}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        p: isTablet ? '6px 8px' : '16px',
        borderRadius: '8px',
        maxWidth: '100%',
        backgroundColor: colors.darkGrey,
        overflowX: 'scroll',
        overflowY: 'hidden',
        scrollbarGutter: 'stable',
        '::-webkit-scrollbar': {
          height: isTablet ? '2px' : '6px',
        },
        '::-webkit-scrollbar-track': {
          background: '#1c2327',
          borderRadius: '8px',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#ec5f59',
          borderRadius: '8px',
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: '#b24b45',
        },
      }}
    >
      {deck[0].rank &&
        deck.map((card, index) => (
          <Slide
            key={card.id}
            direction="left"
            in={true}
            timeout={300}
            onEntered={
              index === deck.length - 1
                ? () => setIsAnimationFinished(true)
                : undefined
            }
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '2px 8px',
                position: 'relative',
              }}
            >
              <GameCard
                cardSx={{
                  width: { xs: '2.5em', md: '5em' },
                  height: { xs: '4em', md: '7.9em' },
                  fontSize: { xs: '1rem', md: '2.2em' },
                }}
                card={card}
                transform
              />
              {index > 0 && deck[index - 1].choice === 'skip' && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: 'calc(100% - 27px)',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 8,
                  }}
                ></Box>
              )}
              {card.choice && (
                <Box
                  sx={{
                    right: { xs: '-33%', md: '-25%' },
                    top: 'calc(50% - 27px)', // 27px - multiplier field height
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    zIndex: 9,
                    backgroundColor: '#fff',
                    color:
                      deck[index + 1].payoutMultiplier === 0
                        ? colors.pink
                        : colors.lightGreen,
                    boxShadow: '#2a2f3c33 0 0 0 2px',
                    borderRadius: '4px',
                    aspectRatio: '1/1',
                    fontSize: '32px',
                  }}
                >
                  {card.choice === 'higher' && (
                    <KeyboardCapslockIcon
                      sx={{ color: 'inherit', fontSize: 'inherit' }}
                    />
                  )}
                  {card.choice === 'lower' && (
                    <KeyboardCapslockIcon
                      sx={{
                        color: 'inherit',
                        fontSize: 'inherit',
                        transform: 'rotate(180deg)',
                      }}
                    />
                  )}
                  {card.choice === 'skip' && (
                    <KeyboardDoubleArrowRightIcon
                      sx={{ color: colors.darkGrey, fontSize: 'inherit' }}
                    />
                  )}
                </Box>
              )}
              <Box
                sx={{
                  textAlign: 'center',
                  width: { xs: '60px', md: '80px' },
                  height: '25px',
                  backgroundColor:
                    card.payoutMultiplier === 0
                      ? colors.pink
                      : colors.lightGreen,
                  borderRadius: '2px',
                  userSelect: 'none',
                  textOverflow: 'ellipsis',
                  fontSize: '14px',
                  color: '#000',
                  fontWeight: 600,
                  overflow: 'hidden',
                  p: '2px',
                  whiteSpace: 'nowrap',
                }}
              >
                {card.id.startsWith('initial')
                  ? 'Start card'
                  : (card.payoutMultiplier !== undefined
                      ? card.payoutMultiplier
                      : 1
                    ).toFixed(2) + '×'}
              </Box>
            </Box>
          </Slide>
        ))}
    </Box>
  );
};

export default Footer;
