import React, {
  useState,
  createContext,
  ReactNode,
  useCallback,
  useEffect,
} from 'react';
import { formatEther } from 'viem';
import httpClient from '../utils/http-client';
import { CardProps } from '../components/Games/common/GameCard';

export type Card = {
  rank:
    | '2'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '10'
    | 'J'
    | 'Q'
    | 'K'
    | 'A';
  suit: 'C' | 'D' | 'H' | 'S' | CardProps['suit'];
  side: 'front' | 'back';
  id?: string;
  color?: 'red' | 'black';
};

interface BlackjackContextProps {
  betAmount: number;
  setBetAmount: React.Dispatch<React.SetStateAction<number>>;
  dealerHand: Card[];
  setDealerHand: React.Dispatch<React.SetStateAction<Card[]>>;
  playerHand: Card[][];
  setPlayerHand: React.Dispatch<React.SetStateAction<Card[][]>>;
  currentHandNumber: number;
  setCurrentHandNumber: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  maxBet: number;
  maxSplits: number;
  gameActive: boolean;
  setGameActive: React.Dispatch<React.SetStateAction<boolean>>;
  setCardAndFlip: (
    card: Card,
    handType: 'player' | 'dealer',
    handNumber?: number,
    flip?: boolean,
  ) => void;
  scores: { player: number[]; dealer: number[] };
  setScores: React.Dispatch<
    React.SetStateAction<{ player: number[]; dealer: number[] }>
  >;
  updateLastCard: (
    card: Card,
    handType: 'player' | 'dealer',
    handNumber?: number,
  ) => void;
  cardSetTimeout: number;
  animationTimout: number;
  addCardsToHand: (
    cards: Card[],
    handType: 'player' | 'dealer',
    handNumber: number,
  ) => void;
}

export const BlackjackContext = createContext<BlackjackContextProps>({
  betAmount: 0,
  setBetAmount: () => {},
  dealerHand: [],
  setDealerHand: () => {},
  playerHand: [],
  setPlayerHand: () => {},
  currentHandNumber: 0,
  setCurrentHandNumber: () => {},
  isLoading: false,
  setIsLoading: () => {},
  maxBet: 150,
  maxSplits: 2,
  gameActive: false,
  setGameActive: () => {},
  setCardAndFlip: () => {},
  scores: { player: [0], dealer: [0] },
  setScores: () => {},
  updateLastCard: () => {},
  cardSetTimeout: 400,
  animationTimout: 300,
  addCardsToHand: () => {},
});

export const BlackjackProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [betAmount, setBetAmount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dealerHand, setDealerHand] = useState<Card[]>([]);
  const [playerHand, setPlayerHand] = useState<Card[][]>([]);
  const [currentHandNumber, setCurrentHandNumber] = useState<number>(0);
  const [maxBet, setMaxBet] = useState<number>(150);
  const [maxSplits] = useState<number>(2);
  const [gameActive, setGameActive] = useState<boolean>(false);
  const [scores, setScores] = useState<{ player: number[]; dealer: number[] }>({
    player: [0],
    dealer: [0],
  });

  const animationTimout = 300;
  const cardSetTimeout = 400;

  const updateLastCard = useCallback(
    (
      card: Card,
      handType: 'player' | 'dealer',
      handNumber: number = 0,
      push: boolean = false,
    ) => {
      if (handType === 'player') {
        setPlayerHand((prevHands) => {
          const updatedHands = [...prevHands];
          const currentHand = updatedHands[handNumber]
            ? [...updatedHands[handNumber]]
            : [];

          if (currentHand.length > 0 && !push) {
            currentHand.splice(currentHand.length - 1, 1, card);
          } else {
            currentHand.push(card);
          }

          updatedHands[handNumber] = currentHand;
          return updatedHands;
        });
      } else if (handType === 'dealer') {
        setDealerHand((prevHand) => {
          const updatedHand = [...prevHand];

          if (updatedHand.length > 0 && !push) {
            updatedHand.splice(updatedHand.length - 1, 1, card);
          } else {
            updatedHand.push(card);
          }
          return updatedHand;
        });
      }
    },
    [],
  );

  const setCardAndFlip = useCallback(
    (
      card: Card,
      handType: 'player' | 'dealer',
      handNumber: number = 0,
      flip: boolean = true,
    ) => {
      const newCard = {
        ...card,
        id: `blackjack-card-${handType}-${Math.random().toString(36)}`,
        color: card.suit === 'D' || card.suit === 'H' ? 'red' : 'black',
        side: 'back',
      } as Card;

      updateLastCard(newCard, handType, handNumber, true);

      if (!flip) return;
      setTimeout(() => {
        updateLastCard(
          { ...newCard, side: 'front' },
          handType,
          handNumber,
          false,
        );
      }, animationTimout);
    },
    [updateLastCard],
  );

  const addCardsToHand = useCallback(
    (cards: Card[], handType: 'player' | 'dealer', handNumber: number = 0) => {
      cards.forEach((card, index) => {
        const isSecondCardForDealer = handType === 'dealer' && index === 1; // Вторая карта дилера

        const newCard = {
          ...card,
          id: `blackjack-card-${handType}-${Math.random().toString(36)}`,
          color: card.suit === 'D' || card.suit === 'H' ? 'red' : 'black',
          side: isSecondCardForDealer ? 'back' : 'front', // Вторая карта дилера лицом вниз
        } as Card;
        updateLastCard(newCard, handType, handNumber, true); // Добавление карты в руку
      });
    },
    [updateLastCard],
  );

  useEffect(() => {
    httpClient
      .get('/blackjack/max-bet')
      .then((res) => {
        setMaxBet(Number(formatEther(res.data.maxBet)));
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <BlackjackContext.Provider
      value={{
        betAmount,
        setBetAmount,
        isLoading,
        setIsLoading,
        dealerHand,
        setDealerHand,
        playerHand,
        setPlayerHand,
        currentHandNumber,
        setCurrentHandNumber,
        maxBet,
        setCardAndFlip,
        gameActive,
        setGameActive,
        scores,
        setScores,
        updateLastCard,
        cardSetTimeout,
        addCardsToHand,
        maxSplits,
        animationTimout,
      }}
    >
      {children}
    </BlackjackContext.Provider>
  );
};
