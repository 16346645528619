import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useChainId } from 'wagmi';
import { isHex } from 'viem';

import httpClient from '../../../utils/http-client';
import { showToast, TOASTTYPE } from '../../../utils/toast';
import { buttonStyle, hoverStyle, textStyle } from '../utils';

const isValidTxHash = (hash: string): boolean => {
  return hash.length === 66 && isHex(hash);
};

const ManualDeposit = () => {
  const chainId = useChainId();

  const [txHash, setTxHash] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    isValidTxHash(value) ? setTxHash(value) : setTxHash('');
  };

  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setInputValue(text);
      if (isValidTxHash(text)) {
        setTxHash(text);
      } else {
        setTxHash('');
      }
    } catch (err) {
      console.error('Failed to paste text: ', err);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    httpClient
      .post('/user/deposit', {
        txnHash: txHash,
        chainId: chainId,
      })
      .then(() => {
        showToast(TOASTTYPE.success, 'Successful deposit!');
      })
      .catch((err) => {
        showToast(TOASTTYPE.error, 'Deposit failed.');
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box sx={{ mt: '8px' }}>
      <Accordion
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.04)',
          color: '#fff',
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                color: '#fff',
                transition: 'transform 0.3s ease-in-out',
                transform: 'rotate(0deg)',
              }}
            />
          }
        >
          <Typography sx={{ fontWeight: 700, fontSize: '14px' }}>
            Deposit not credited?
            <br />
            Manually submit deposit TX hash.
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            label="Enter TX Hash"
            variant="outlined"
            fullWidth
            value={inputValue}
            onChange={handleChange}
            sx={{ marginBottom: 2 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handlePaste}
                    edge="end"
                    sx={{ color: '#fff' }}
                  >
                    <Typography
                      sx={{
                        ...textStyle,
                        ...buttonStyle,
                        '&:hover': hoverStyle,
                        height: 'min-content',
                        padding: '6px 8px',
                      }}
                    >
                      PASTE
                    </Typography>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
          >
            <Button
              sx={{ ...buttonStyle, '&:hover': hoverStyle, width: '100%' }}
              disabled={!txHash || loading}
              onClick={handleSubmit}
            >
              <Typography sx={{ ...textStyle }}>Submit</Typography>
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ManualDeposit;
