/* eslint-disable no-self-assign */
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import '@rainbow-me/rainbowkit/styles.css';

import logo from '../../assets/images/coqbook_logo.svg';
import small_logo from '../../assets/images/coqbook_logo_small.svg';

import BalanceDisplay from './BalanceDisplay';
import CoqConnectButton from './CoqConnectButton';

const drawerWidth = 165;

type HeaderProps = {
  handleDrawerToggle: () => void;
};

export default function Header({ handleDrawerToggle }: HeaderProps) {
  const refreshIframe = () => {
    const ifr: any = document.getElementsByName('embeddediframe')[0];
    if (!ifr) return;
    ifr.src = ifr.src;
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        backgroundColor: '#000',
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Link
          to={'/'}
          style={{
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            alt="logo"
            sx={{
              height: { xs: '40px', sm: '45px' },
              objectFit: 'cover',
              content: {
                xs: `url(${small_logo})`,
                sm: `url(${logo})`,
              },
            }}
            onClick={refreshIframe}
          />
        </Link>
        <Box sx={{ flex: 1, padding: 0, margin: 0 }} />
        <Box>
          <BalanceDisplay />
        </Box>
        <Box sx={{ flex: 1, padding: 0, margin: 0 }} />
        <Box>
          <CoqConnectButton />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
