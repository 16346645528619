import { Box } from '@mui/material';

import PageWrapper from '../../../wrappers/PageWrapper';
import GameField from './GameField/GameField';
import BetMenu from './BetMenu/BetMenu';

import { MinesProvider } from '../../../contexts/MinesContext';
import { gameContainerStyles } from '../utils';

const MinesGame = () => {
  return (
    <MinesProvider>
      <PageWrapper>
        <Box sx={gameContainerStyles}>
          <BetMenu />
          <GameField />
        </Box>
      </PageWrapper>
    </MinesProvider>
  );
};

export default MinesGame;
