import { HILO_HOUSE_EDGE } from './config';

type CardRank =
  | 'A'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | 'J'
  | 'Q'
  | 'K';

const ranks: CardRank[] = [
  'A',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  'J',
  'Q',
  'K',
];

export const getProbabilityAndMultiplier = (
  currentRank: CardRank | string,
  choice: 'higher' | 'lower',
) => {
  const rankIndex = ranks.indexOf(currentRank as CardRank);

  if (rankIndex === -1) {
    return {
      probability: 0,
      multiplier: 0,
    };
  }

  const totalRanks = ranks.length;

  let probabilityHigherOrSame = (totalRanks - rankIndex) / totalRanks;
  let probabilityLowerOrSame = (rankIndex + 1) / totalRanks;

  if (rankIndex === totalRanks - 1) {
    probabilityHigherOrSame = 1 / totalRanks;
    probabilityLowerOrSame = 1 - probabilityHigherOrSame;
  }

  if (rankIndex === 0) {
    probabilityLowerOrSame = 1 / totalRanks;
    probabilityHigherOrSame = 1 - probabilityLowerOrSame;
  }

  const multiplierHigherOrSame =
    (1 / probabilityHigherOrSame) * HILO_HOUSE_EDGE;
  const multiplierLowerOrSame = (1 / probabilityLowerOrSame) * HILO_HOUSE_EDGE;

  if (choice === 'higher') {
    return {
      probability: probabilityHigherOrSame,
      multiplier: multiplierHigherOrSame,
    };
  }
  return {
    probability: probabilityLowerOrSame,
    multiplier: multiplierLowerOrSame,
  };
};
