import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  useMediaQuery,
  useTheme,
  Skeleton,
} from '@mui/material';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import { format } from 'date-fns';

import {
  colors,
  green,
  pink,
  greyWhite,
  blackGradient,
} from '../../utils/colors';
import { getFormattedTotal } from '../../utils/utils';
import httpClient from '../../utils/http-client';
import { McoqPriceContext } from '../../contexts/McoqPriceContext';

const getTotalsData = async (): Promise<any> => {
  const response = await httpClient.get('/stats/totals-data');
  return response.data as any;
};

const getBetsChartData = async (): Promise<any> => {
  const response = await httpClient.get('/stats/bets-chart-data');
  return response.data as any;
};

const getActiveUserChartData = async (): Promise<any> => {
  const response = await httpClient.get('/stats/active-user-chart-data');
  return response.data as any;
};

const getPnlChartData = async (): Promise<any> => {
  const response = await httpClient.get('/stats/pnl-chart-data');
  return response.data as any;
};

const getDepositWithdrawalData = async (): Promise<any> => {
  const response = await httpClient.get('/stats/deposit-withdrawal-chart-data');
  return response.data as any;
};

const CustomTooltip = ({
  active,
  payload,
  label,
  price,
}: {
  active: any;
  payload: any;
  label: any;
  price?: number;
}) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          backgroundColor: colors.blackRussian,
          backgroundImage: blackGradient,
          border: '2px solid ' + colors.pink,
          padding: '10px',
          borderRadius: '4px',
          boxShadow: '0 0 6px rgba(0,0,0,0.1)',
          color: 'white',
        }}
      >
        <Typography>{`Date: ${label}`}</Typography>
        {payload.map((data) => {
          const formattedValue = getFormattedTotal(data.value);
          const dollarValue = price
            ? `$${getFormattedTotal(data.value * price)}`
            : '';

          return (
            <Box key={data.name}>
              <Typography
                style={{ color: data.color }}
              >{`${data.name}: ${formattedValue}${dollarValue ? ` (${dollarValue})` : ''}`}</Typography>
            </Box>
          );
        })}
      </Box>
    );
  }

  return null;
};

interface TotalsData {
  totalWithdrawals: number;
  totalDeposits: number;
  totalVolume: number;
  totalBets: number;
  totalPnl: number;
  totalUsers: number;
  totalBurn: number;
}

function Stats() {
  const [totalsData, setTotalsData] = useState<NonNullable<TotalsData>>({
    totalWithdrawals: -1,
    totalDeposits: -1,
    totalVolume: -1,
    totalBets: -1,
    totalPnl: -1,
    totalUsers: -1,
    totalBurn: -1,
  });
  const [betsChartData, setBetsChartData] = useState<Array<unknown>>([]);
  const [pnlChartData, setPnlChartData] = useState<Array<unknown>>([]);
  const [depositWithdrawalChartData, setDepositWithdrawalChartData] = useState<
    Array<unknown>
  >([]);
  const [activeUserChartData, setActiveUserChartData] = useState<
    Array<unknown>
  >([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { price } = useContext(McoqPriceContext);

  useEffect(() => {
    getBetsChartData().then((chartsData) => setBetsChartData(chartsData));
    getTotalsData().then((totals) => setTotalsData(totals));
    getPnlChartData().then((chartsData) => setPnlChartData(chartsData));
    getDepositWithdrawalData().then((chartsData) => {
      const combinedData = chartsData.weeklyDeposits.map(
        (deposit: any, index: number) => ({
          ...deposit,
          total_withdrawals:
            chartsData.weeklyWithdrawals[index]?.total_withdrawals,
        }),
      );
      setDepositWithdrawalChartData(combinedData);
    });
    getActiveUserChartData().then((chartsData) =>
      setActiveUserChartData(chartsData),
    );
  }, []);

  const renderChart = (
    type: 'line' | 'bar',
    data: any,
    lines: { dataKey: string; color: string; name: string }[],
    xAxisKey: string,
    usdYAxis?: boolean,
  ) => {
    const ChartComponent = type === 'line' ? LineChart : BarChart;
    const getFormattedValue = (value: number, usdPrefix?: boolean) => {
      let formattedValue = '';

      if (usdPrefix && Math.abs(value) >= 1000) {
        formattedValue = (value / 1000).toFixed(0) + 'K';
      } else {
        formattedValue = value.toString();
      }

      formattedValue = formattedValue.replace(/\.0K$/, 'K');

      return usdPrefix ? `$${formattedValue}` : formattedValue;
    };

    const formatDate = (date: string) => format(new Date(date), 'dd/MM/yy');

    return (
      <Grid item xs={12} sm={6}>
        <ResponsiveContainer
          width="100%"
          height={320}
          style={{
            backgroundColor: colors.midnightBlack,
            borderRadius: '16px',
            paddingTop: '20px',
          }}
        >
          <ChartComponent
            data={data}
            margin={{ top: 5, right: usdYAxis ? 0 : 20, left: 0, bottom: 5 }}
          >
            <CartesianGrid stroke="white" />
            <XAxis
              dataKey={xAxisKey}
              stroke="white"
              tickFormatter={formatDate}
            />
            <YAxis
              yAxisId="left"
              tickFormatter={(value: number) => getFormattedValue(value)}
              stroke="white"
            />
            {usdYAxis && (
              <YAxis
                yAxisId="right"
                orientation="right"
                stroke="white"
                includeHidden
                tickFormatter={(value) =>
                  getFormattedValue(value * price, true)
                }
              />
            )}
            <Tooltip
              // @ts-ignore
              content={<CustomTooltip price={usdYAxis ? price : undefined} />}
            />
            <Legend
              payload={lines.map((line) => ({
                id: line.dataKey,
                value: line.name,
                type: 'line',
                color: line.color,
              }))}
            />
            {lines.map((line) =>
              type === 'line' ? (
                <Line
                  key={line.dataKey}
                  type="monotone"
                  dataKey={line.dataKey}
                  stroke={line.color}
                  name={line.name}
                  yAxisId="left"
                  strokeWidth={2}
                />
              ) : (
                <Bar
                  key={line.dataKey}
                  type="monotone"
                  dataKey={line.dataKey}
                  stroke={line.color}
                  name={line.name}
                  yAxisId="left"
                  strokeWidth={2}
                  activeBar={<Rectangle fill="pink" stroke="blue" />}
                />
              ),
            )}
            {usdYAxis &&
              lines.map((line) =>
                type === 'line' ? (
                  <Line
                    key={line.dataKey}
                    type="monotone"
                    dataKey={line.dataKey}
                    stroke={line.color}
                    name={line.name}
                    yAxisId="right"
                    hide
                  />
                ) : (
                  <Bar
                    key={line.dataKey}
                    type="monotone"
                    dataKey={line.dataKey}
                    stroke={line.color}
                    name={line.name}
                    yAxisId="right"
                    hide
                  />
                ),
              )}
          </ChartComponent>
        </ResponsiveContainer>
      </Grid>
    );
  };

  const renderTotalContainer = (title: string, value: number) => (
    <Grid item xs={12} sm={6} md={3}>
      <Box
        textAlign="center"
        sx={{
          backgroundColor: colors.midnightBlack,
          borderRadius: '6px',
          paddingY: '8px',
          height: '110px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* MCOQ value */}
        {value === -1 ? (
          <Skeleton width={100} height={30} />
        ) : (
          <Typography color={pink} sx={{ fontSize: 21 }}>
            {getFormattedTotal(value)} {!title.includes('users') && 'MCOQ'}
          </Typography>
        )}
        {/* USD value */}
        {value === -1
          ? !title.includes('users') && <Skeleton width={80} height={25} />
          : !title.includes('users') && (
              <Typography color={green}>
                {'$' + getFormattedTotal(value * price)}
              </Typography>
            )}
        {/* Title */}
        <Typography color={greyWhite}>{title}</Typography>
      </Box>
    </Grid>
  );

  return (
    <Container maxWidth="lg" sx={{ marginTop: 1 }}>
      <Grid container spacing={3} justifyContent="center">
        {renderTotalContainer('Total active users', totalsData.totalUsers)}
        {renderTotalContainer('Total Volume', totalsData.totalVolume)}
        {renderTotalContainer('Total House PNL', totalsData.totalPnl)}
        {renderTotalContainer('Total coqburn', totalsData.totalBurn)}
      </Grid>
      <Grid container spacing={3} justifyContent="center" mt={1}>
        {renderChart(
          'line',
          activeUserChartData.slice(0, -1),
          [{ dataKey: 'count', color: pink, name: 'Active users (Weekly)' }],
          'start_date',
        )}
        {renderChart(
          'line',
          betsChartData.slice(0, -1),
          [
            {
              dataKey: 'volume',
              color: pink,
              name: 'MCOQ Bet Volume (Weekly)',
            },
          ],
          'start_date',
          isMobile ? false : true,
        )}
        {renderChart(
          'line',
          depositWithdrawalChartData.slice(0, -1),
          [
            {
              dataKey: 'total_deposits',
              color: green,
              name: 'Total Deposits (Weekly)',
            },
            {
              dataKey: 'total_withdrawals',
              color: pink,
              name: 'Total Withdrawals (Weekly)',
            },
          ],
          'start_date',
          isMobile ? false : true,
        )}
        {renderChart(
          'bar',
          pnlChartData.slice(0, -1),
          [
            {
              dataKey: 'total_pnl',
              color: pink,
              name: 'House PNL in MCOQ (Weekly)',
            },
          ],
          'start_date',
          isMobile ? false : true,
        )}
      </Grid>
    </Container>
  );
}

export default Stats;
