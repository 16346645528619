import { Box, Toolbar, Typography } from '@mui/material';
import Footer from '../components/Footer/Footer';
import { Outlet } from 'react-router-dom';

const PageWrapper = ({
  title,
  children,
}: {
  title?: string;
  children?: React.ReactNode;
}) => {
  return (
    <Box
      component="main"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        width: '100%',
        color: '#fff',
        p: '16px',
      }}
    >
      <Toolbar variant="dense" />
      <Box
        sx={{
          flexGrow: 1,
          width: '100%',
          position: 'relative',
          maxWidth: '1300px',
          margin: '0 auto',
          overflow: 'auto',
          '&::-webkit-scrollbar': { display: 'none' },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        <Box sx={{ paddingBottom: '8px', marginBottom: '42px' }}>
          <Typography variant="h4" color={'fff'}>
            {title}
          </Typography>
        </Box>
        <Outlet />
        {children}
      </Box>
      <Box sx={{ pt: 4, display: 'flex', justifyContent: 'center' }}>
        <Footer />
      </Box>
    </Box>
  );
};

export default PageWrapper;
