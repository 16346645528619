import { Box } from '@mui/material';

import PageWrapper from '../../../wrappers/PageWrapper';
import GameField from './GameField/GameField';
import BetMenu from './BetMenu/BetMenu';

import { DiceProvider } from '../../../contexts/DiceContext';
import { gameContainerStyles } from '../utils';

const DiceGame = () => {
  return (
    <DiceProvider>
      <PageWrapper>
        <Box sx={gameContainerStyles}>
          <BetMenu />
          <GameField />
        </Box>
      </PageWrapper>
    </DiceProvider>
  );
};

export default DiceGame;
