import React, { useContext, useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { MinesContext } from '../../../../contexts/MinesContext';
import { colors } from '../../../../utils/colors';
import httpClient from '../../../../utils/http-client';
import { game, next } from '../mockRequest';

import bomb from '../../../../assets/images/coqbomb.svg';
import gem from '../../../../assets/images/ruby_egg.svg';

const cellColors = {
  orange: '#ff9800',
  transparentOrange: 'rgba(255, 143, 40, .2)',
  red: 'rgb(255 59 105)',
  transparentRed: 'rgba(255, 59, 105, .2)',
  redShadow: '0px 0px 15px -2px rgb(255 59 105)',
};

const Cell = ({ number }: { number: number }) => {
  const {
    bombCells,
    setBombCells,
    selectedCells,
    setSelectedCells,
    isActive,
    setIsActive,
    isLoading,
    setIsLoading,
    setCurrentMultiplier,
    setNextMultiplier,
  } = useContext(MinesContext);

  // showing all gems and bombs after end of game
  const [isEndGameCell, setIsEndGameCell] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (!isActive && bombCells.length > 0 && !selectedCells.includes(number)) {
      setIsEndGameCell(true);
    } else if (isActive || bombCells.length === 0) {
      setIsEndGameCell(false);
    }
  }, [selectedCells, bombCells, isActive]);

  useEffect(() => {
    if (!isActive || selectedCells.includes(number) || isLoading) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [isActive, selectedCells, isLoading]);

  let bgColor = selectedCells.includes(number)
    ? cellColors.transparentOrange
    : colors.grey800;
  bgColor = bombCells.includes(number) ? cellColors.transparentRed : bgColor;

  const borderStyles = {
    borderRadius: '8px',
    border:
      selectedCells.includes(number) && !bombCells.includes(number)
        ? `2px solid ${cellColors.orange}`
        : '2px solid transparent',
    '&:hover': {
      borderColor: colors.grey500,
    },
    boxShadow:
      selectedCells.includes(number) && bombCells.includes(number)
        ? cellColors.redShadow
        : 'none',
  };
  borderStyles.border =
    selectedCells.includes(number) && bombCells.includes(number)
      ? `2px solid ${cellColors.red}`
      : borderStyles.border;

  const selectCell = () => {
    setIsLoading(true);
    httpClient
      .post('/games/mines/next', {
        fields: [number],
      })
      .then((response) => {
        setIsLoading(false);
      })
      .catch((error) => {
        // mock
        const response = next([number]);
        console.log(response);
        if (!response?.active) {
          setIsActive(false);
          setSelectedCells([...selectedCells, number]);
          setBombCells(response?.state.mines || []);
          setCurrentMultiplier(-1);
          setNextMultiplier(0);
        } else {
          setSelectedCells([...selectedCells, number]);
          setCurrentMultiplier(response.state.currentMultiplier);
          setNextMultiplier(response.state.nextMultiplier);
        }
        setIsLoading(false);
      });
  };

  return (
    <Box
      onClick={isActive ? selectCell : () => undefined}
      sx={{
        ...borderStyles,
        opacity: isEndGameCell ? '0.6' : '1',
        position: 'relative',
        width: '100%',
        aspectRatio: '1 / 1',
        cursor: isDisabled ? 'default' : 'pointer',
        pointerEvents: isDisabled ? 'none' : 'auto',
        backgroundColor: bgColor,
        transition: 'all 0.15s cubic-bezier(0.4, 0, 0.2, 1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        userSelect: 'none',
      }}
    >
      {bombCells.includes(number) && (
        <Box
          component="img"
          src={bomb}
          sx={{
            padding: { xs: '4px', md: '12px' },
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        ></Box>
      )}
      {((selectedCells.includes(number) && !bombCells.includes(number)) ||
        (isEndGameCell && !bombCells.includes(number))) && (
        <Box
          component="img"
          src={gem}
          sx={{
            padding: { xs: '4px', md: '12px' },
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        ></Box>
      )}
    </Box>
  );
};

export default Cell;
