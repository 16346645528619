import httpClient from '../utils/http-client';

export const activeGame = async (): Promise<any> => {
  const resp = await httpClient.get('/blackjack/active-game');
  return resp.data;
};

export const startGame = async (
  amount: string,
  currency: string,
): Promise<any> => {
  const resp = await httpClient.post('/blackjack/bet', { amount, currency });
  return resp.data;
};

export const hit = async (): Promise<any> => {
  const resp = await httpClient.post('/blackjack/hit');
  return resp.data;
};

export const stand = async (): Promise<any> => {
  const resp = await httpClient.post('/blackjack/stand');
  return resp.data;
};

export const doubleDown = async (): Promise<any> => {
  const resp = await httpClient.post('/blackjack/double');
  return resp.data;
};

export const split = async (): Promise<any> => {
  const resp = await httpClient.post('/blackjack/split');
  return resp.data;
};

export const insurance = async (action: 'insurance' | 'noInsurance') => {
  return {};
};
