import React from 'react';
import './App.css';
import AppLayout from './components/AppLayout';
import { UserProvider } from './contexts/UserContext';
import { McoqPriceProvider } from './contexts/McoqPriceContext';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ApolloProvider } from '@apollo/client';
import apolloClient from './utils/apollo-client';
import { colors } from './utils/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF0000',
    },
    secondary: {
      main: '#ec5f59',
    },
    info: {
      main: '#FFF',
    },
    paper: {
      main: '#1c2327',
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#1c2327',
          color: '#FFF',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: colors.transparentPink,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'pink',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red', // default outline color when focused
          },
          '&.Mui-hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'pink', // default outline color when focused
          },
          color: 'pink',
        },
        input: {
          '& .MuiOutlinedInput-notchedOutline': {
            color: 'pink',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            color: 'red', // default outline color when focused
          },
          color: 'pink', // Default text color
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'pink', // Label text color
          '&.Mui-focused': {
            color: 'red', // Label color when focused
          },
        },
      },
    },
  },
  typography: {
    fontFamily: ['Aeonik', 'sans-serif'].join(','),
  },
});

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <McoqPriceProvider>
        <UserProvider>
          <ThemeProvider theme={theme}>
            <div className="App">
              <AppLayout />
            </div>
          </ThemeProvider>
        </UserProvider>
      </McoqPriceProvider>
    </ApolloProvider>
  );
}

export default App;
