import { IconButton, styled } from '@mui/material';
import { colors } from '../../../utils/colors';

const InputButton = styled(IconButton)(({ theme }) => ({
  minWidth: '40px',
  padding: '8px 12px',
  backgroundColor: colors.pink,
  '&:hover': {
    backgroundColor: colors.transparentPink,
  },
  color: '#fff',
  '&:disabled': {
    backgroundColor: colors.transparentPink,
    color: '#000',
  },
  fontSize: '1rem',
  borderRadius: '4px',
}));

export default InputButton;
