type Round = {
  field: number;
  payoutMultiplier: number;
};
type State = {
  mines: number[] | null;
  minesCount: number;
  gridSize: number;
  currentMultiplier: number;
  nextMultiplier: number;
  rounds: Round[];
};

type GameResponse = {
  active: boolean;
  amount: number;
  id: string;
  payout: number;
  payoutMultiplier: number;
  state: State;
};

const generateUniqueRandomNumbers = (
  count: number,
  min: number,
  max: number,
): number[] => {
  const numbers = new Set<number>();
  while (numbers.size < count) {
    numbers.add(Math.floor(Math.random() * (max - min + 1)) + min);
  }
  return Array.from(numbers);
};
class Game {
  currentGame?: GameResponse;
  mines?: number[];
  constructor(game?: GameResponse) {
    this.currentGame = game;
    if (game) {
      this.mines = generateUniqueRandomNumbers(
        game.state.minesCount,
        0,
        game.state.gridSize - 1,
      );
    }
  }
}

export const game = new Game();
export const bet = (
  amount: number,
  minesCount: number,
  gridSize: number,
): GameResponse => {
  const resp = {
    active: true,
    amount: amount,
    id: '123',
    payout: 0,
    payoutMultiplier: 0,
    state: {
      mines: null,
      minesCount: minesCount,
      currentMultiplier: 0,
      nextMultiplier: 1.15,
      gridSize: gridSize,
      rounds: [],
    },
  };
  game.currentGame = resp;
  game.mines = generateUniqueRandomNumbers(
    resp.state.minesCount,
    0,
    resp.state.gridSize - 1,
  );
  console.log(game.mines);
  return resp;
};
export const next = (fields: number[]): GameResponse | undefined => {
  if (!game.currentGame) return;
  if (!game.mines) return;
  if (fields.filter((field) => game.mines?.includes(field)).length > 0) {
    const resp = {
      active: false,
      amount: 0,
      id: '123',
      payout: 0,
      payoutMultiplier: 0,
      state: {
        mines: game.mines || [],
        minesCount: 0,
        currentMultiplier: 0,
        nextMultiplier: 0,
        gridSize: 0,
        rounds: [],
      },
    };
    game.currentGame = undefined;
    game.mines = undefined;
    return resp;
  }
  const newRound = {
    field: fields[0],
    payoutMultiplier: game.currentGame.state.nextMultiplier,
  };
  game.currentGame.state.currentMultiplier =
    game.currentGame.state.nextMultiplier;
  game.currentGame.state.nextMultiplier *= 1.15;
  game.currentGame.state.rounds.push(newRound);
  return game.currentGame;
};

export const cashout = () => {
  const resp = game.currentGame;
  if (!resp) return;
  resp.active = false;
  resp.payout = resp.state.currentMultiplier * resp.amount;
  resp.payoutMultiplier = resp.state.currentMultiplier;
  resp.state.mines = game.mines || [];
  game.currentGame = undefined;
  game.mines = undefined;
  return resp;
};
