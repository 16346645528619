import { useContext, useCallback } from 'react';
import { Button, Box } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import {
  HiloContext,
  Card as CardProps,
} from '../../../../contexts/HiloContext';
import { higher, lower, skipCard } from '../../../../api/hilo';
import { colors } from '../../../../utils/colors';
import { getProbabilityAndMultiplier } from '../../../../utils/getHiloProbabiltyMultiplier';

const CustomButton = ({
  children,
  callback,
}: {
  children: React.ReactNode;
  callback: () => Promise<any>;
}) => {
  const { gameActive, setGameActive, setNewCard, isLoading, swipeCard } =
    useContext(HiloContext);

  const onClick = useCallback(async () => {
    swipeCard();
    const game = await callback();

    const rank = game.state.rounds.slice(-1)[0].card.rank;
    const suit = game.state.rounds.slice(-1)[0].card.suit;
    setNewCard(
      false,
      rank as CardProps['rank'],
      suit as CardProps['suit'],
      game.state.rounds.slice(-1)[0].guess,
      game.state.rounds[game.state.rounds.length - 1].payoutMultiplier,
    );

    if (game.active !== gameActive) setGameActive(game.active);
  }, [callback, gameActive, setGameActive, swipeCard, setNewCard]);

  return (
    <Button
      onClick={onClick}
      disabled={!gameActive || isLoading}
      sx={{
        position: 'relative',
        width: '100%',
        justifyContent: 'center',
        backgroundColor: colors.grey800,
        '&:hover': {
          backgroundColor: colors.freshGrey,
        },
        overflow: 'hidden',
        borderRadius: '4px',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        textTransform: 'none',
        color: colors.textWhite,
        '&:disabled': {
          opacity: 0.5,
          color: colors.freshWhite,
        },
        fontWeight: 700,
      }}
    >
      {children}
    </Button>
  );
};

const HigherButton = () => {
  const { currentCard } = useContext(HiloContext);
  const { probability } = getProbabilityAndMultiplier(
    currentCard.rank,
    'higher',
  );
  let text = currentCard.rank === 'K' ? 'Equal' : 'Higher or equal';
  text = currentCard.rank === 'A' ? 'Higher' : text;
  const icon =
    currentCard.rank === 'K' ? (
      <DragHandleIcon sx={{ color: colors.lightYellow }} />
    ) : (
      <KeyboardArrowUpIcon sx={{ color: colors.pink }} />
    );
  return (
    <CustomButton callback={higher}>
      {text} {icon} {(probability * 100).toFixed(2)}%
    </CustomButton>
  );
};

const LowerButton = () => {
  const { currentCard } = useContext(HiloContext);
  const { probability } = getProbabilityAndMultiplier(
    currentCard.rank,
    'lower',
  );
  let text = currentCard.rank === 'A' ? 'Equal' : 'Lower or equal';
  text = currentCard.rank === 'K' ? 'Lower' : text;
  const icon =
    currentCard.rank === 'A' ? (
      <DragHandleIcon sx={{ color: colors.lightYellow }} />
    ) : (
      <KeyboardArrowDownIcon sx={{ color: colors.lightGreen }} />
    );
  return (
    <CustomButton callback={lower}>
      {text} {icon} {(probability * 100).toFixed(2)}%
    </CustomButton>
  );
};

const SkipButton = () => {
  return (
    <CustomButton callback={skipCard}>
      Skip card {<KeyboardDoubleArrowRightIcon sx={{ fontSize: '22px' }} />}
    </CustomButton>
  );
};

const GameButtons = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        width: '100%',
        my: '16px',
      }}
    >
      <HigherButton />
      <LowerButton />
      <SkipButton />
    </Box>
  );
};

export default GameButtons;
