import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, MenuItem, TextField, Box, Typography } from '@mui/material';
import { Dropdown, Option } from '../components/FilterPopover';

import { colors } from '../../../utils/colors';

const OPTIONS: Option[] = [
  { label: 'All Stakes', data: { min: null, max: null } },
  { label: '< 10 MCOQ', data: { min: null, max: 10 } },
  { label: '10 MCOQ - 100 MCOQ', data: { min: 10, max: 100 } },
  { label: '> 100 MCOQ', data: { min: 100, max: null } },
];

const Inputs = ({ setAnchorEl }) => {
  const [minInputValue, setMinInputValue] = useState<string>('');
  const [maxInputValue, setMaxInputValue] = useState<string>('');

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    name === 'minStake' ? setMinInputValue(value) : setMaxInputValue(value);
  };

  const onClick = () => {
    const min =
      minInputValue === ''
        ? null
        : parseFloat(parseFloat(minInputValue).toFixed(2));
    const max =
      maxInputValue === ''
        ? null
        : parseFloat(parseFloat(maxInputValue).toFixed(2));

    min
      ? searchParams.set('minStake', min.toString())
      : searchParams.delete('minStake');
    max
      ? searchParams.set('maxStake', max.toString())
      : searchParams.delete('maxStake');

    navigate({ search: searchParams.toString() });
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 1,
        p: '12px',
      }}
    >
      <TextField
        inputProps={{ min: 0 }}
        id="outlined-basic"
        autoComplete="off"
        type="number"
        variant="outlined"
        name="minStake"
        placeholder="min"
        value={minInputValue}
        onChange={onChange}
      />
      <TextField
        inputProps={{ min: 0 }}
        id="outlined-basic"
        autoComplete="off"
        type="number"
        variant="outlined"
        name="maxStake"
        placeholder="max"
        value={maxInputValue}
        onChange={onChange}
      />
      <Button
        onClick={onClick}
        variant="contained"
        sx={{ width: '100%', bgcolor: colors.pink }}
      >
        Apply
      </Button>
    </Box>
  );
};

const StakeSizeFilter = ({ isLoading }: { isLoading: boolean }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const selectOption = (optionData: Option['data']) => {
    optionData.min
      ? searchParams.set('minStake', optionData.min.toString())
      : searchParams.delete('minStake');
    optionData.max
      ? searchParams.set('maxStake', optionData.max.toString())
      : searchParams.delete('maxStake');
    navigate({ search: searchParams.toString() });
    setAnchorEl(null);
  };

  const getPreviewText = () => {
    if (searchParams.get('minStake') && searchParams.get('maxStake')) {
      return `${searchParams.get('minStake')} MCOQ - ${searchParams.get('maxStake')} MCOQ`;
    } else if (searchParams.get('minStake')) {
      return `> ${searchParams.get('minStake')} MCOQ`;
    } else if (searchParams.get('maxStake')) {
      return `< ${searchParams.get('maxStake')} MCOQ`;
    } else {
      return 'All Stakes';
    }
  };

  return (
    <Dropdown
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      title="Filter By Stake Size"
      previewText={getPreviewText()}
      isLoading={isLoading}
      mainOptions={OPTIONS}
      selectOptionCallback={selectOption}
      subOption={<Inputs setAnchorEl={setAnchorEl} />}
    />
  );
};

export default StakeSizeFilter;
