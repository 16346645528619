import { useContext } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { colors } from '../../../../utils/colors';
import { MinesContext } from '../../../../contexts/MinesContext';

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: colors.textWhite,
  fontSize: '14px',
  fontWeight: 600,
  paddingX: '4px',
  marginBottom: '4px',
}));

const MultiplierField = ({ betAmount }: { betAmount: number }) => {
  const { currentMultiplier, nextMultiplier } = useContext(MinesContext);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: '100%',
        marginY: '16px',
        padding: '16px',
        backgroundColor: colors.darkGrey,
        borderRadius: '8px',
        border: '1px solid ' + colors.borderGrey,
      }}
    >
      <StyledTypography>Current profit</StyledTypography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <StyledTypography>
          {(betAmount * currentMultiplier).toFixed(4)}
        </StyledTypography>
        <StyledTypography>{currentMultiplier.toFixed(2)}</StyledTypography>
      </Box>

      <Box
        sx={{
          width: '100%',
          border: '1px solid ' + colors.borderGrey,
          marginY: '8px',
        }}
      />

      <StyledTypography>Profit on next tile</StyledTypography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <StyledTypography>
          {(betAmount * nextMultiplier).toFixed(4)}
        </StyledTypography>
        <StyledTypography>{nextMultiplier.toFixed(2)}</StyledTypography>
      </Box>
    </Box>
  );
};

export default MultiplierField;
