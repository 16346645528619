import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Box, keyframes } from '@mui/material';

import GameCard, {
  CardProps,
} from '../../../../components/Games/common/GameCard';
import { colors } from '../../../../utils/colors';

type HandProps = {
  deckRef: React.RefObject<HTMLDivElement | null>;
  hand: CardProps[];
  score: number;
  status?: '' | 'win' | 'lose' | 'draw' | 'pick';
  outlineDelayMs?: number;
};
const Hand = ({
  deckRef,
  hand,
  score,
  status,
  outlineDelayMs = 500,
}: HandProps) => {
  const [deckCoords, setDeckCoords] = useState({ x: 0, y: 0 });
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (deckRef.current && containerRef.current) {
      const deckRect = deckRef.current.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();

      setDeckCoords({
        x: deckRect.left - containerRect.left,
        y: deckRect.top - containerRect.top,
      });
    }
  }, [deckRef, hand]);

  const slide = (index: number) => keyframes`
    0% {
      left: ${deckCoords.x / 2}px;
      top: ${deckCoords.y}px;
    }
    100% {
      left: calc(${index * 50}%);
      top: ${index * 10}%;
    }
  `;

  const getOutline = useCallback((status: HandProps['status']) => {
    switch (status) {
      case 'win':
        return '5px solid ' + colors.lightGreen;
      case 'lose':
        return '5px solid ' + colors.pink;
      case 'draw':
        return '5px solid ' + colors.yellow;
      case 'pick':
        return '5px solid blue';
      default:
        return 'none';
    }
  }, []);

  return (
    <Box position={'relative'} width={'100%'}>
      <Box
        ref={containerRef}
        sx={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          columnGap: '8px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            mt: '1em',
            fontSize: '1.2em',
            minWidth: '5em',
            minHeight: '7.9em',
            transitionDuration: '2000ms',
          }}
        >
          {score > 0 && (
            <Box
              sx={{
                position: 'absolute',
                backgroundColor: colors.grey500,
                width: '7ch',
                fontSize: '14px',
                top: 'calc(-30% - 0.25em)',
                left: 'calc(130% - 2em)',
                borderRadius: '999px',
                color: colors.freshWhite,
                textAlign: 'center',
                zIndex: 10,
              }}
            >
              {score}
            </Box>
          )}
          {hand.map((card, i) => (
            <Box
              key={card.id}
              sx={{
                position: 'absolute',
                animation: `${slide(i)} 0.3s ease-in-out forwards`,
              }}
            >
              <Box
                sx={{
                  transition: 'transform 1s ease-in-out',
                  transitionDelay: '0s',
                  transform: `translate(-${hand.length * 10}%, -${hand.length * 5}%)`,
                  width: '5em',
                  height: '7.9em',
                }}
              >
                <GameCard
                  card={card}
                  transform={true}
                  cardSx={{ width: 'inherit', height: '100%', fontSize: '2em' }}
                  outline={getOutline(status)}
                  outlineDelayMs={outlineDelayMs}
                />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Hand;
