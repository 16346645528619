import { useContext, useEffect, useState } from 'react';
import { Box, Typography, Fade, styled } from '@mui/material';

import { HiloContext } from '../../../../contexts/HiloContext';
import { colors } from '../../../../utils/colors';

const ShadowedLine = styled('div')({
  padding: '0px',
  borderBottom: '3px solid ' + colors.freshGrey,
  width: '32px',
  transition: 'opacity 0.5s ease',
  margin: '6px 0',
});

const ProfitModal = () => {
  const { betAmount, deck, gameActive } = useContext(HiloContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (deck.length < 2) {
      setOpen(false);
      return;
    }
    if (
      !gameActive &&
      deck.length > 1 &&
      (deck[deck.length - 1].payoutMultiplier || 0) > 0
    ) {
      setOpen(true);
    }
  }, [gameActive, deck]);

  return (
    <Fade in={open} timeout={{ enter: 500, exit: 250 }}>
      <Box
        sx={{
          width: 'max-content',
          height: 'max-content',
          backgroundColor: colors.darkGrey,
          borderRadius: '8px',
          border: '3px solid ' + colors.lightGreen,
          textAlign: 'center',
          transform: 'translate(-50%, -50%)',
          position: 'absolute',
          top: '50%',
          left: '50%',
          zIndex: 11,
          display: open ? 'flex' : 'none',
          flexDirection: 'column',
          p: '16px 32px',
          alignItems: 'center',
          justifyItems: 'center',
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 700, color: colors.lightGreen }}
        >
          {deck[deck.length - 1].payoutMultiplier?.toFixed(2)}×
        </Typography>
        <ShadowedLine />
        <Typography
          variant="body1"
          sx={{ fontWeight: 700, color: colors.lightGreen }}
        >
          {(betAmount * (deck[deck.length - 1].payoutMultiplier || 1)).toFixed(
            8,
          )}
        </Typography>
      </Box>
    </Fade>
  );
};

export default ProfitModal;
