import { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import '@rainbow-me/rainbowkit/styles.css';

import Sidebar from './Sidebar/Sidebar';
import Header from './Header/Header';

import Support from './Support/Support';

import Profile from './UserPage/Profile';
import Transactions from './UserPage/Transactions';
import BetHistory from './UserPage/BetHistory';
import Settings from './UserPage/Settings';

import KenoGame from './Games/Keno/Keno';
import DiceGame from './Games/Dice/Dice';
import Mines from './Games/Mines/Mines';
import HiloGame from './Games/Hilo/Hilo';
import BlackjackGame from './Games/Blackjack/Blackjack';

import StatsPage from './StatsPage/StatsPage';
import Betstream from './BetActivity/Betstream';
import BetCardModal from './BetActivity/BetCardModal';

import { PARTNER_ID, SPORTSBOOK_URL } from '../utils/config';
import { useUserDetails } from '../contexts/UserContext';

import ShudianApp from 'shudian';

const SportsbookWrapper = () => {
  const { userJWT } = useUserDetails();

  return (
    <Box
      sx={{
        px: 2,
        mt: 8,
        height: 'calc(100vh - 64px)',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <ShudianApp
        basePath="/sportsbook"
        uri={SPORTSBOOK_URL}
        partner_id={PARTNER_ID}
        authToken={userJWT}
        callback={(shadowRoot) => {
          const firstElementChild =
            shadowRoot.firstElementChild as HTMLDivElement;
          if (firstElementChild)
            firstElementChild.style.height = 'calc(100vh - 64px)';
        }}
      />
    </Box>
  );
};

export default function AppLayout() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  return (
    <Box sx={{ backgroundColor: '#000', display: 'flex' }}>
      <CssBaseline />
      <BrowserRouter>
        <Header handleDrawerToggle={handleDrawerToggle} />
        <Sidebar
          mobileOpen={mobileOpen}
          handleDrawerClose={handleDrawerClose}
          handleDrawerTransitionEnd={handleDrawerTransitionEnd}
        />
        <Routes>
          <Route path="/" element={<Navigate to="/sportsbook" replace />} />
          <Route path="sportsbook/*" element={<SportsbookWrapper />} />
          <Route path="/support" element={<Support />} />
          <Route path="/statistics" element={<StatsPage />} />
          <Route path="/betstream" element={<Betstream />} />

          <Route path="/profile" element={<Profile />} />
          <Route path="/bethistory" element={<BetHistory />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/settings" element={<Settings />} />

          <Route path="/games/dice" element={<DiceGame />} />
          <Route path="/games/blackjack" element={<BlackjackGame />} />

          <Route path="/games/keno" element={<KenoGame />} />
          <Route path="/games/mines" element={<Mines />} />
          <Route path="/games/hilo" element={<HiloGame />} />
        </Routes>
        <BetCardModal />
      </BrowserRouter>
    </Box>
  );
}
