import React, { useState, createContext, ReactNode, useEffect } from 'react';
import { getDiceMultiplier, getDiceWinChance } from '../utils/utils';
import { DICE_HOUSE_EDGE } from '../utils/config';
import httpClient from '../utils/http-client';
import { formatEther } from 'viem';

interface DiceContextProps {
  betAmount: number;
  setBetAmount: React.Dispatch<React.SetStateAction<number>>;
  target: number;
  setTarget: React.Dispatch<React.SetStateAction<number>>;
  winChance: number;
  setWinChance: React.Dispatch<React.SetStateAction<number>>;
  currentMultiplier: number;
  setCurrentMultiplier: React.Dispatch<React.SetStateAction<number>>;
  condition: 'below' | 'above';
  setCondition: React.Dispatch<React.SetStateAction<'below' | 'above'>>;
  toggleCondition: () => void;
  pastBets: any[];
  setPastBets: React.Dispatch<React.SetStateAction<any[]>>;
  setTargetAndWinChance: (target?: number, winChance?: number) => void;
  setMultiplier: (newMultiplier: number) => void;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  maxBet: number;
}

export const DiceContext = createContext<DiceContextProps>({
  betAmount: 0,
  setBetAmount: () => {},
  target: 50,
  setTarget: () => {},
  winChance: 50,
  setWinChance: () => {},
  currentMultiplier: 0,
  setCurrentMultiplier: () => {},
  condition: 'above',
  setCondition: () => {},
  toggleCondition: () => {},
  pastBets: [],
  setPastBets: () => {},
  setTargetAndWinChance: () => {},
  setMultiplier: () => {},
  isLoading: false,
  setIsLoading: () => {},
  maxBet: 150,
});

export const DiceProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [betAmount, setBetAmount] = useState<number>(0);
  const [target, setTarget] = useState<number>(50);
  const [winChance, setWinChance] = useState<number>(50);
  const [currentMultiplier, setCurrentMultiplier] = useState<number>(1.98);
  const [condition, setCondition] = useState<'below' | 'above'>('above');
  const [pastBets, setPastBets] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [maxBet, setMaxBet] = useState<number>(150);

  const toggleCondition = () => {
    setCondition(condition === 'below' ? 'above' : 'below');
  };

  useEffect(() => {
    httpClient
      .get('/dice/max-bet')
      .then((res) => {
        setMaxBet(Number(formatEther(res.data.maxBet)));
      })
      .catch((err) => console.log(err));
  }, []);

  const setTargetAndWinChance = (
    newTarget?: number,
    newWinChance?: number,
    newMultiplier?: number,
  ) => {
    let win = 0;
    if (newTarget !== undefined) {
      win = condition === 'below' ? newTarget : 100 - newTarget;
      setTarget(newTarget);
      setWinChance(win);
    } else if (newWinChance !== undefined) {
      setWinChance(newWinChance);
      setTarget(condition === 'below' ? newWinChance : 100 - newWinChance);
    }
    setCurrentMultiplier(
      newMultiplier
        ? newMultiplier
        : getDiceMultiplier({
            winChance: newWinChance || win,
            houseEdge: DICE_HOUSE_EDGE,
          }),
    );
  };

  const setMultiplier = (newMultiplier: number) => {
    newMultiplier = Number(newMultiplier.toFixed(4));
    setTargetAndWinChance(
      undefined,
      getDiceWinChance({
        multiplier: newMultiplier,
        houseEdge: DICE_HOUSE_EDGE,
      }),
      newMultiplier,
    );
  };

  useEffect(() => {
    setTargetAndWinChance(target);
  }, [condition]);

  return (
    <DiceContext.Provider
      value={{
        betAmount,
        setBetAmount,
        target,
        setTarget,
        winChance,
        setWinChance,
        currentMultiplier,
        setCurrentMultiplier,
        condition,
        setCondition,
        toggleCondition,
        pastBets,
        setPastBets,
        setTargetAndWinChance,
        setMultiplier,
        isLoading,
        setIsLoading,
        maxBet,
      }}
    >
      {children}
    </DiceContext.Provider>
  );
};
