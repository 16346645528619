import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { SPORTSBOOK_URL } from './config';
import { setContext } from '@apollo/client/link/context';

const apolloClient = new ApolloClient({
  link: new HttpLink({
    uri: `${SPORTSBOOK_URL}graphql`,
    // fetchOptions: {
    //   mode: 'same-origin'
    // }
  }),
  cache: new InMemoryCache(),
});

export default apolloClient;
