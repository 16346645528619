import { Box } from '@mui/material';

import History from './History';
import GameSlider from './Slider';
import Footer from './Footer';

import { gameFieldStyles } from '../../utils';

const GameField = () => {
  return (
    <Box sx={{ flexGrow: { xl: 1 }, width: '100%', height: '100%' }}>
      <Box sx={gameFieldStyles}>
        <History />
        <GameSlider />
        <Footer />
      </Box>
    </Box>
  );
};

export default GameField;
